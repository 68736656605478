import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { DropdownSuggest } from '../common';
import useStyles from '../../styles/goals/shareGoalSectionStyles';

const ShareGoalSection = ({
  sharedWithError,
  setSharedWithError,
  sharedWith,
  setShareToUser
}) => {
  const classes = useStyles();

  const chooseShareWith = user => {
    setSharedWithError('');
    if (user) {
      setShareToUser({
        name: user.displayName,
        email: user.mail || user.userPrincipalName,
        employeeId: user.employeeId
      });
    } else {
      setShareToUser({ name: null, email: null, employeeId: null });
    }
  };

  const renderNotSharedComponent = () => {
    return (
      <div className={classes.goalNotSharedContainer}>
        <Typography variant="h5" component="h5" className={classes.subtitle}>
          Share Goal
        </Typography>
        <Typography className={classes.subtext}>
          SHARE WITH AN INDIVIDUAL TO GET FEEDBACK. GOAL WILL STILL BE
          ACCESSIBLE ON YOUR DASHBOARD.
        </Typography>
        <div className={classes.shareWithDropdown}>
          <DropdownSuggest
            labelName="Share With"
            onChange={chooseShareWith}
            errorMessage={sharedWithError}
          />
        </div>
      </div>
    );
  };

  const renderSharedComponent = () => {
    return (
      <div className={classes.goalSharedContainer}>
        <Typography
          variant="h6"
          component="h6"
          className={classes.sharedWithHeader}
        >
          <span> Currently Shared With: </span>
          &nbsp;
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          className={classes.sharedWithSubtitle}
        >
          {`${sharedWith.name} ${sharedWith.email}`}
        </Typography>
      </div>
    );
  };

  const chooseShareSectionDisplay = () => {
    return sharedWith.name
      ? renderSharedComponent()
      : renderNotSharedComponent();
  };

  return (
    <section className={classes.shareSection}>
      {chooseShareSectionDisplay()}
    </section>
  );
};

ShareGoalSection.propTypes = {
  sharedWithError: PropTypes.string.isRequired,
  setSharedWithError: PropTypes.func.isRequired,
  sharedWith: PropTypes.objectOf(PropTypes.any).isRequired,
  setShareToUser: PropTypes.func.isRequired
};

export default ShareGoalSection;
