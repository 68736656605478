import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from './Dropdown';
import useStyles from '../../styles/common/dashboardStyles';

const ViewSelector = ({ view, setViewSelection, viewOptions }) => {
  const classes = useStyles();

  return (
    <div className={classes.viewSelectContainer}>
      <Dropdown
        margin="dense"
        labelName="Views"
        labelwidth={45}
        options={viewOptions}
        dropdownLocation="dashboard"
        onChange={setViewSelection}
        defaultValue={view}
      />
    </div>
  );
};

ViewSelector.propTypes = {
  view: PropTypes.string.isRequired,
  setViewSelection: PropTypes.func.isRequired,
  viewOptions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ViewSelector;
