import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import { MAX_COMMENT_CHARS } from '../../../helpers/constants';
import { getMaxCommentLengthText } from '../../../helpers/helpers';
import useStyles from '../../../styles/evals/previousCommentsStyles';

/* TextField for adding new comments */
const Comment = React.memo(
  ({
    updateCommentValues,
    sectionId,
    textLabel,
    disableCommentField,
    classname,
    defaultVal
  }) => {
    const [value, setValue] = useState(defaultVal);
    const [commentLength, setCommentLength] = useState();
    const classes = useStyles();
    const ref = useRef();

    useEffect(() => {
      setValue(defaultVal);
      setCommentLength(0);
    }, [defaultVal]);

    useEffect(() => {
      const { current } = ref;

      const handleBlur = () => {
        if (value) updateCommentValues(value, sectionId);
      };
      if (current) current.addEventListener('blur', handleBlur);

      return () => {
        if (current) current.removeEventListener('blur', handleBlur);
      };
    });

    const handleChange = event => {
      const newComment = event.target.value;

      setValue(newComment);
      setCommentLength(newComment.length || 0);
    };

    return (
      <div className={classes.textField}>
        <TextField
          variant="outlined"
          label={textLabel}
          margin="normal"
          data-cy="commentField"
          multiline
          fullWidth
          onChange={handleChange}
          disabled={disableCommentField}
          className={`${classes[classname]}`}
          value={value}
          inputRef={ref}
          inputProps={{
            maxLength: MAX_COMMENT_CHARS
          }}
          helperText={getMaxCommentLengthText(commentLength)}
        />
      </div>
    );
  }
);

Comment.defaultProps = {
  classname: '',
  defaultVal: undefined
};

Comment.propTypes = {
  updateCommentValues: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  textLabel: PropTypes.string.isRequired,
  disableCommentField: PropTypes.bool.isRequired,
  classname: PropTypes.string,
  defaultVal: PropTypes.string
};

export default Comment;
