import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { useFormDispatch, useFormState } from '../context/FormContext';
import { useGoalDispatch, useGoalState } from '../context/GoalContext';
import { useDashboardSearchDispatch } from '../context/DashboardSearchContext';
import { useGoalsDashboardSearchDispatch } from '../context/GoalsDashboardSearchContext';
import { useAuth } from '../context/AuthContext';
import { MODAL, STATUS, NAVBAR_ACTION } from '../helpers/constants';
import { CancelModal } from './evaluations/FormModals';
import InitiateEval from './evaluations/InitiateEval';
import InitiateGoal from './goals/InitiateGoal';
import { toCamel } from '../helpers/helpers';
import useStyles from '../styles/common/navbarStyles';
import hpLogo from '../assets/Perf-Plus_White_navBar.png';

const mobileNavItems = [
  { id: 'navId-0', text: 'New Evaluation', nav: NAVBAR_ACTION.EVALPAGE },
  { id: 'navId-1', text: NAVBAR_ACTION.EVALDASH, nav: NAVBAR_ACTION.EVALDASH },
  { id: 'navId-2', text: 'New Goal', nav: NAVBAR_ACTION.GOALPAGE },
  { id: 'navId-3', text: NAVBAR_ACTION.GOALDASH, nav: NAVBAR_ACTION.GOALDASH },
  { id: 'navId-4', text: NAVBAR_ACTION.REPORTS, nav: NAVBAR_ACTION.REPORTS },
  { id: 'navId-5', text: NAVBAR_ACTION.LOGOUT, nav: NAVBAR_ACTION.LOGOUT }
];

const REPORTS_URL =
  'https://app.powerbi.com/Redirect?action=OpenReport&appId=6c1f2d8c-7a79-42f2-8de6-b538fa5b69ec&reportObjectId=4284b7b4-6076-4276-8847-0f3226a80c37&ctid=aeeffdfb-cb01-4af6-a78f-fcaba7a6fad8';

const Navbar = ({ route }) => {
  const { dispatch } = useFormDispatch();
  const { goalDispatch } = useGoalDispatch();
  const { dashboardSearchDispatch } = useDashboardSearchDispatch();
  const { goalsDashboardSearchDispatch } = useGoalsDashboardSearchDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEvals, setAnchorElEvals] = useState(null);
  const [anchorElGoals, setAnchorElGoals] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [navRoute, setNavRoute] = useState(null);
  const { userData, logout } = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const {
    state: {
      formConfig: { status },
      existingPpeInfo,
      unsavedEvalChanges
    }
  } = useFormState();
  const {
    state: { unsavedGoalChanges }
  } = useGoalState();

  const openMobileMenu = event => setAnchorEl(event.currentTarget);
  const openEvaluationMenu = event => setAnchorElEvals(event.currentTarget);
  const openGoalMenu = event => setAnchorElGoals(event.currentTarget);

  const menuItemIsDisabled = route === '/newGoal' || route === '/newEvaluation';

  // confirmation modal is only necessary if form content changes have been made
  const discardModalNeeded =
    (route === '/evaluation' && unsavedEvalChanges) ||
    (route === '/goal' && unsavedGoalChanges);

  const closeMenus = () => {
    setAnchorEl(null);
    setAnchorElEvals(null);
    setAnchorElGoals(null);
  };

  const openEvalModal = () => {
    closeMenus();
    resetEvalContext();
    redirect('/dashboard');
    setOpenModal(MODAL.EVAL);
  };

  const openGoalModal = () => {
    closeMenus();
    resetGoalContext();
    redirect('/goalsDashboard');
    setOpenModal(MODAL.GOAL);
  };

  const evalNavItems = [
    {
      id: 'evalNavItem-1',
      text: NAVBAR_ACTION.NEWEVAL,
      onClickOne: NAVBAR_ACTION.NEWEVAL,
      onClickTwo: openEvalModal
    },
    {
      id: 'evalNavItem-2',
      text: NAVBAR_ACTION.EVALDASH,
      onClickOne: NAVBAR_ACTION.EVALDASH,
      onClickTwo: () => redirect('/dashboard')
    }
  ];

  const goalNavItems = [
    {
      id: 'goalNavItem-1',
      text: NAVBAR_ACTION.NEWGOAL,
      onClickOne: NAVBAR_ACTION.NEWGOAL,
      onClickTwo: openGoalModal
    },
    {
      id: 'goalNavItem-2',
      text: NAVBAR_ACTION.GOALDASH,
      onClickOne: NAVBAR_ACTION.GOALDASH,
      onClickTwo: () => redirect('/goalsDashboard')
    }
  ];

  const closeModal = () => {
    closeMenus();
    setOpenModal(null);
    setNavRoute(null);
  };

  const handleLogout = () => {
    logout();
    return <Redirect to="/" />;
  };

  const redirect = routeTo => {
    const {
      location: { pathname }
    } = history;

    dispatch({ type: 'setAuditData', payload: {} });

    if (pathname === '/dashboard') {
      dashboardSearchDispatch({ type: 'clearSearch' });
    } else if (pathname === '/goalsDashboard') {
      goalsDashboardSearchDispatch({ type: 'clearSearch' });
    }
    closeModal();
    history.push(routeTo);
  };

  const handleNavigation = choice => {
    // Evaluation routes
    if (choice === NAVBAR_ACTION.EVALDASH) redirect('/dashboard');
    if (choice === NAVBAR_ACTION.NEWEVAL) openEvalModal();
    if (choice === NAVBAR_ACTION.EVALPAGE) redirectToNewEvalPage();
    if (choice === NAVBAR_ACTION.REPORTS) window.open(REPORTS_URL, '_blank');

    // Goals routes
    if (choice === NAVBAR_ACTION.GOALDASH) redirect('/goalsDashboard');
    if (choice === NAVBAR_ACTION.NEWGOAL) openGoalModal();
    if (choice === NAVBAR_ACTION.GOALPAGE) redirectToNewGoalPage();

    if (choice === NAVBAR_ACTION.LOGOUT) handleLogout();
  };

  // Create truncated user name to display in the navbar
  const createDisplayName = () => {
    const { givenName, surname } = userData;

    const MAX_CHARS = 21;
    // Limit the first name to the max amount of characters
    const displayFirstName = givenName.substring(0, MAX_CHARS);
    let displayLastName = '';
    // If the full name is less than the max amount of characters, display the full name as is
    if (displayFirstName.length + surname.length < MAX_CHARS) {
      displayLastName = surname;
      // if the first name is 3 characters less than or equal the max, then only display the first initial of the last name followed by a period
    } else if (displayFirstName.length <= MAX_CHARS - 3) {
      displayLastName = `${surname.substring(0, 1)}.`;
    }
    return `${displayFirstName} ${displayLastName}`.toUpperCase();
  };

  const chooseDesktopNavClickAction = item => {
    return discardModalNeeded
      ? () => chooseModalDisplayType(item.onClickOne)
      : item.onClickTwo;
  };

  const chooseModalDisplayType = navChoice => {
    if (
      (status && status !== STATUS.OPEN.NAME) ||
      !discardModalNeeded ||
      navChoice === NAVBAR_ACTION.REPORTS
    ) {
      setOpenModal(null);
      handleNavigation(navChoice);
    } else {
      setOpenModal(MODAL.DISCARD);
      setNavRoute(navChoice);
    }
  };

  const resetGoalContext = () => {
    goalDispatch({
      type: 'setGoalFormConfig',
      payload: {
        goalTitle: null,
        goalForName: null,
        goalForEmail: null,
        goalForEmployeeId: null
      }
    });
  };

  const resetEvalContext = () => {
    dispatch({ type: 'setFormConfigObject', payload: {} });
    dispatch({ type: 'setAuditData', payload: {} });
  };

  const redirectToNewEvalPage = () => {
    resetEvalContext();
    closeModal();
    closeMenus();
    history.push('/newEvaluation');
  };

  const redirectToNewGoalPage = () => {
    resetGoalContext();
    closeModal();
    closeMenus();
    history.push('/newGoal');
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar disableGutters>
          <div className={classes.logo}>
            <img src={hpLogo} edge="start" className="HP-logo" alt="logo" />
          </div>
          {/* Mobile Menu Items */}
          <div className={classes.hamburgerMenu}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={openMobileMenu}
              data-cy="navbar-menu-icon"
            >
              <MenuIcon className={classes.menuButton} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenus}
              style={{ top: '42px' }}
            >
              {mobileNavItems.map(item => {
                return (
                  <div key={`${item.id}-div`}>
                    <MenuItem
                      key={item.id}
                      onClick={() => chooseModalDisplayType(item.nav)}
                      data-cy={toCamel(item.text)}
                      disabled={menuItemIsDisabled}
                    >
                      {item.text.toUpperCase()}
                    </MenuItem>
                    {(item.id === 'navId-1' || item.id === 'navId-3') && (
                      <hr key={`${item.id}-hr`} />
                    )}
                  </div>
                );
              })}
            </Menu>
          </div>
          {/* Desktop Menu Items */}
          <div className={classes.toolbarItems}>
            <Button
              onClick={openEvaluationMenu}
              className={classes.buttonText}
              data-cy="Evaluations"
            >
              Evaluations
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElEvals}
              keepMounted
              open={Boolean(anchorElEvals)}
              onClose={closeMenus}
              style={{ top: '42px' }}
            >
              {evalNavItems.map(item => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={chooseDesktopNavClickAction(item)}
                    data-cy={toCamel(item.text)}
                    disabled={menuItemIsDisabled}
                  >
                    {item.text}
                  </MenuItem>
                );
              })}
            </Menu>
            <Button
              onClick={openGoalMenu}
              className={classes.buttonText}
              data-cy="Goals"
            >
              Goals
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElGoals}
              keepMounted
              open={Boolean(anchorElGoals)}
              onClose={closeMenus}
              style={{ top: '42px' }}
            >
              {goalNavItems.map(item => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={chooseDesktopNavClickAction(item)}
                    disabled={menuItemIsDisabled}
                    data-cy={toCamel(item.text)}
                  >
                    {item.text}
                  </MenuItem>
                );
              })}
            </Menu>
            <Button
              key="reportsNav"
              onClick={() => handleNavigation(NAVBAR_ACTION.REPORTS)}
              className={classes.buttonText}
              data-cy="reports"
              disabled={menuItemIsDisabled}
            >
              REPORTS
            </Button>
            <Button
              key="logoutNav"
              onClick={() => chooseModalDisplayType(NAVBAR_ACTION.LOGOUT)}
              className={classes.buttonText}
              data-cy="logout"
              disabled={menuItemIsDisabled}
            >
              LOGOUT
            </Button>
            <span className={classes.userName} data-cy="User Name" variant="h6">
              {createDisplayName()}
            </span>
          </div>
        </Toolbar>
      </AppBar>
      {openModal === MODAL.GOAL && (
        <InitiateGoal
          isOpen={openModal === MODAL.GOAL}
          closeModal={closeModal}
          renderType="modal"
        />
      )}
      {openModal === MODAL.EVAL && !existingPpeInfo && (
        <InitiateEval
          isOpen={openModal === MODAL.EVAL}
          closeModal={closeModal}
          renderType="modal"
        />
      )}
      {openModal === MODAL.DISCARD && (
        <CancelModal
          close={closeModal}
          action={() => handleNavigation(navRoute)}
        />
      )}
    </div>
  );
};

Navbar.propTypes = {
  route: PropTypes.string.isRequired
};

export default Navbar;
