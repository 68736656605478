import { useState, useEffect } from 'react';

import {
  DASHBOARD_VIEWS,
  GOAL_DASH_VIEWS,
  MAX_COMMENT_CHARS
} from './constants';

/* Converts plain english, snake case, or kabob case to camel case */
/* Does not work with Pascal case */
const toCamel = string =>
  string
    .split(/[\s_-]/)
    .map((word, i) => {
      const camel = word.toLowerCase().replace(/\W/g, '');

      return i ? camel.replace(/\w/, match => match.toUpperCase()) : camel;
    })
    .join('');

const truncateDate = date => date.substr(0, 10);

const convertToUpperCase = item => {
  return typeof item === 'string' ? item.toUpperCase() : item;
};

/* Debounce to delay hitting API */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const getCurrentDate = () => new Date().toISOString();

// Helper functions for eval & goals dashboards

const dashboardTitleDisplay = (selection, dashboardType) => {
  let title;
  const displayOptions = {
    evals: ([title] = Object.keys(DASHBOARD_VIEWS)
      .filter(view => DASHBOARD_VIEWS[view].SHORT === selection)
      .map(view => DASHBOARD_VIEWS[view].LONG)),
    goals: ([title] = Object.values(GOAL_DASH_VIEWS).filter(
      view => view === selection
    ))
  };
  title = displayOptions[dashboardType];
  return title;
};

const updateResponse = (
  sectionId,
  questionId,
  field,
  responseText,
  newResponses,
  setNewResponses,
  formResponses
) => {
  let response = newResponses.find(
    r => r.sectionId === sectionId && r.questionId === questionId
  );

  // if response exists, update it
  if (response) {
    response[field] = responseText;
  } else {
    // append the new response
    response = {
      id: formResponses[sectionId][questionId].id || 0,
      sectionId,
      questionId,
      [field]: responseText
    };
    setNewResponses(prevResponses => {
      return [...prevResponses, response];
    });
  }
};

const regularSort = (itemA, itemB, order) => {
  if (itemA === itemB && itemA !== null) return 0;
  if (order === 'desc') {
    return itemA < itemB || itemB === null ? -1 : 1;
  }
  return itemA < itemB || itemB === null ? 1 : -1;
};

const getMaxCommentLengthText = length => {
  const plural = length > 1 ? 's' : '';
  return length > 0
    ? `${length} character${plural} of maximum ${MAX_COMMENT_CHARS}`
    : '';
};

const formIsReadOnly = (userEmail, assignEmail, userId, assignId, view) => {
  const readOnlyViews = [
    DASHBOARD_VIEWS.EXEC.SHORT,
    DASHBOARD_VIEWS.BOARD.SHORT,
    DASHBOARD_VIEWS.MANAGER.SHORT
  ];

  const emailsMatch = idMatch(userEmail, assignEmail);
  const employeeIdsMatch = idMatch(userId, assignId);

  return readOnlyViews.includes(view) && !emailsMatch && !employeeIdsMatch;
};

const removeDuplicates = array => {
  const stringifiedArray = array.map(item => JSON.stringify(item));
  const uniques = [...new Set(stringifiedArray)];
  return uniques.map(item => JSON.parse(item));
};

const getErrorMessage = error => {
  // to fix Apollo Server failing to correctly return graphQLErrors array
  let newError = error;
  if (error.networkError && error.networkError.result) {
    if (error.networkError.result.errors) {
      const [{ message }] = error.networkError.result.errors;
      newError = message;
    }
  }
  return newError;
};

// Returns true if identifiers such as email addresses or employeeIds match
const idMatch = (idOne, idTwo) => {
  const idExists = !!idOne;
  return idExists && idOne === idTwo;
};

export {
  toCamel,
  useDebounce,
  getCurrentDate,
  dashboardTitleDisplay,
  truncateDate,
  updateResponse,
  regularSort,
  convertToUpperCase,
  getMaxCommentLengthText,
  formIsReadOnly,
  removeDuplicates,
  getErrorMessage,
  idMatch
};
