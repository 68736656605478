import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core/';

import useStyles from '../../../styles/goals/goalPrevCommentsStyles';

const GoalPreviousComment = ({
  commentId,
  comment,
  commentCreator,
  commentDate,
  checkBox,
  gatherCommentsForDeletion
}) => {
  const [checked, setChecked] = useState(false);
  const classes = useStyles();

  const handleCheckChange = id => {
    setChecked(!checked);
    gatherCommentsForDeletion(id, !checked);
  };

  return (
    <div className={`${classes.commentDetails}`}>
      <div className={classes.commentInfoContain}>
        <p className={classes.commentCreator}>{commentCreator}</p>
        <p className={classes.commentDate}>{commentDate}</p>
      </div>
      <div className={classes.textFieldContainer}>
        <TextField
          value={comment}
          multiline
          fullWidth
          disabled
          className={classes.textField}
        />
      </div>
      {checkBox && (
        <div
          className={
            checked ? classes.checkBoxContainer : classes.uncheckedContainer
          }
        >
          <FormControlLabel
            control={
              <Checkbox // eslint-disable-line
                checked={checked}
                onChange={() => handleCheckChange(commentId)}
                value="Delete Comment"
                className={classes.checkBox}
                data-cy="deleteCheckbox"
              />
            }
            label="Delete Comment"
          />
        </div>
      )}
    </div>
  );
};

GoalPreviousComment.propTypes = {
  commentId: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  commentCreator: PropTypes.string.isRequired,
  commentDate: PropTypes.string.isRequired,
  checkBox: PropTypes.bool.isRequired,
  gatherCommentsForDeletion: PropTypes.func.isRequired
};

export default GoalPreviousComment;
