import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    },
    '& .MuiOutlinedInput-root': {
      height: 56
    }
  },
  newEvalPageContainer: {
    marginTop: 115,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    }
  },
  pageFormControl: {
    width: '80%',
    marginBottom: 55
  },
  pageTextField: {
    width: '80%',
    marginBottom: 55,
    '& .MuiOutlinedInput-root': {
      height: 56
    }
  },
  pageVersionTitle: {
    marginBottom: 55
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  button: {
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    padding: theme.spacing(1.75),
    minWidth: 95,
    '&.Mui-disabled': {
      backgroundColor: 'rgba(166, 25, 46, 0.4)',
      color: globalTheme.palette.neutral.contrast
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      padding: theme.spacing(1.25)
    }
  },
  positiveButton: {
    color: globalTheme.palette.neutral.contrast,
    backgroundColor: globalTheme.palette.primary.main,
    '&:hover': {
      color: globalTheme.palette.primary.main,
      border: '1px solid #a6192e'
    }
  },
  negativeButton: {
    color: 'black',
    backgroundColor: globalTheme.palette.neutral.contrast,
    borderColor: 'black'
  }
}));

export default useStyles;
