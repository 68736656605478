import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const GoalsDashboardSearchStateContext = createContext();
const GoalsDashboardSearchDispatchContext = createContext();

const useGoalsDashboardSearchState = () =>
  useContext(GoalsDashboardSearchStateContext);
const useGoalsDashboardSearchDispatch = () =>
  useContext(GoalsDashboardSearchDispatchContext);

const initialState = {
  endDateDisabled: true,
  startDate: {},
  endDate: {},
  openSearchValue: '',
  status: [],
  category: [],
  track: [],
  goalsDashboardSearchResults: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setStartDate':
      return {
        ...state,
        endDateDisabled: false,
        startDate: { ...action.payload }
      };
    case 'resetStartDate':
      return {
        ...state,
        endDateDisabled: true,
        startDate: {}
      };
    case 'setEndDate':
      return {
        ...state,
        endDate: { ...action.payload }
      };
    case 'setStatus':
      return {
        ...state,
        status: action.payload
      };
    case 'setCategory':
      return {
        ...state,
        category: action.payload
      };
    case 'setTrack':
      return {
        ...state,
        track: action.payload
      };
    case 'setOpenSearch':
      return {
        ...state,
        openSearchValue: action.payload
      };
    case 'updateGoalsSearchResultsData':
      return {
        ...state,
        goalsDashboardSearchResults: action.payload
      };
    case 'clearSearch':
      return {
        ...state,
        endDateDisabled: true,
        startDate: {},
        endDate: {},
        openSearchValue: '',
        status: [],
        category: [],
        track: []
      };
    default:
      throw new Error();
  }
};

const GoalsDashboardSearchContextProvider = ({ children }) => {
  const [state, goalsDashboardSearchDispatch] = useReducer(
    reducer,
    initialState
  );
  return (
    <GoalsDashboardSearchStateContext.Provider value={{ state }}>
      <GoalsDashboardSearchDispatchContext.Provider
        value={{ goalsDashboardSearchDispatch }}
      >
        {children}
      </GoalsDashboardSearchDispatchContext.Provider>
    </GoalsDashboardSearchStateContext.Provider>
  );
};

GoalsDashboardSearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export {
  GoalsDashboardSearchContextProvider,
  useGoalsDashboardSearchState,
  useGoalsDashboardSearchDispatch
};
