import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import useStyles from '../../styles/common/modalStyles';

const Modal = ({
  modalTitle,
  leftBtnText,
  rightBtnText,
  leftBtnClick,
  rightBtnClick,
  middleBtnText,
  middleBtnClick,
  isOpen,
  handleClose,
  children,
  disabled
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {modalTitle}
      </DialogTitle>
      {middleBtnText ? (
        <>
          <DialogContent
            className={
              modalTitle === 'Duplicate'
                ? `${classes.dialogContent}`
                : `${classes.errorDialogContent} ${classes.dialogContent}`
            }
          >
            {children}
          </DialogContent>
          <DialogActions className={classes.dialogActionButtons}>
            <Button
              className={`${classes.button} ${classes.positiveButton}`}
              onClick={middleBtnClick}
              data-cy="backBtn"
            >
              {middleBtnText}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent className={classes.dialogContent}>
            {children}
          </DialogContent>
          <DialogActions className={classes.dialogActionButtons}>
            <Button
              className={`${classes.button} ${classes.negativeButton}`}
              onClick={leftBtnClick}
              variant="outlined"
              data-cy="backBtn"
            >
              {leftBtnText}
            </Button>
            <Button
              className={`${classes.button} ${classes.positiveButton}`}
              onClick={rightBtnClick}
              disabled={disabled}
              data-cy="confirmBtn"
            >
              {rightBtnText}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

Modal.defaultProps = {
  modalTitle: '',
  leftBtnText: '',
  rightBtnText: '',
  leftBtnClick: () => {},
  rightBtnClick: () => {},
  middleBtnText: '',
  middleBtnClick: () => {},
  disabled: false
};

Modal.propTypes = {
  modalTitle: PropTypes.string,
  leftBtnText: PropTypes.string,
  rightBtnText: PropTypes.string,
  leftBtnClick: PropTypes.func,
  rightBtnClick: PropTypes.func,
  middleBtnText: PropTypes.string,
  middleBtnClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

export default Modal;
