import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  appBar: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 70,
    backgroundColor: globalTheme.palette.neutral.main,
    [theme.breakpoints.up('sm')]: {
      height: 80
    },
    '@media print': {
      display: 'none'
    }
  },
  buttonText: {
    paddingRight: 20,
    color: 'white',
    fontSize: '1rem',
    '&.MuiButton-root.Mui-disabled': {
      color: 'white'
    }
  },
  hamburgerMenu: {
    position: 'absolute',
    right: 20,
    [theme.breakpoints.up('sm')]: {
      right: 80
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  logo: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    marginLeft: 20,
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 80
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      width: 'auto',
      marginLeft: 60,
      marginTop: 7
    }
  },
  menuButton: {
    fontSize: '3rem'
  },
  userName: {
    padding: '0px 20px',
    borderLeft: '1px solid grey',
    fontSize: '1rem',
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      visibility: 'visible'
    }
  },
  toolbarItems: {
    width: '100%',
    visibility: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 60,
      width: 'auto',
      visibility: 'visible'
    }
  }
}));

export default useStyles;
