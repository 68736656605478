import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useFormState } from '../../context/FormContext';
import {
  FETCH_FORM_STRUCTURE,
  FETCH_PPE_RESPONSES,
  FETCH_PPE_COMMENTS,
  FETCH_PPE_INFO
} from '../../graphql/evalQueries';
import CreateFormContainer from '../../components/evaluations/CreateForm';
import { LoadingSpinner } from '../../components/common';
import { getErrorMessage } from '../../helpers/helpers';

const EvaluationContainer = () => {
  const {
    state: { formConfig }
  } = useFormState();
  const history = useHistory();

  useEffect(() => {
    if (!formConfig.formTypeId) {
      history.push('/dashboard');
    }
  }, [formConfig.formTypeId, history]);

  return <Evaluation formConfig={formConfig} />;
};

/* Queries to fetch form structure and any existing responses/comments for this form */
/* Passes this data to CreateForm */
const Evaluation = React.memo(({ formConfig }) => {
  const formConfigHasProperties = Object.keys(formConfig).length;

  // This will prevent trying to update form in case user navigates away to create new evaluation from within another evaluation.
  if (!formConfigHasProperties) {
    return null;
  }

  // Adding `no-cache` fetch policy allows for removal of refetch()
  const useFormQueries = () => {
    const structureQuery = useQuery(FETCH_FORM_STRUCTURE, {
      variables: { formTypeId: formConfig.formTypeId },
      skip: !formConfig.formTypeId && !formConfig.ppeId,
      fetchPolicy: 'no-cache'
    });

    const ppeInfo = useQuery(FETCH_PPE_INFO, {
      variables: { ppeId: formConfig.ppeId },
      skip: !formConfig.ppeId,
      fetchPolicy: 'no-cache'
    });

    const responseQuery = useQuery(FETCH_PPE_RESPONSES, {
      variables: { ppeId: formConfig.ppeId },
      skip: !formConfig.ppeId,
      fetchPolicy: 'no-cache'
    });

    const commentsQuery = useQuery(FETCH_PPE_COMMENTS, {
      variables: { ppeId: formConfig.ppeId },
      skip: !formConfig.ppeId,
      fetchPolicy: 'no-cache'
    });
    return [structureQuery, ppeInfo, responseQuery, commentsQuery];
  };

  const [
    { data: formData, loading: formLoading, error: formError },
    { data: formInfo, loading: formInfoLoading, error: formInfoError },
    { data: responseData, loading: responseLoading, error: responseError },
    { data: commentsData, loading: commentsLoading, error: commentsError }
  ] = useFormQueries();

  if (formLoading || formInfoLoading || responseLoading || commentsLoading) {
    return <LoadingSpinner />;
  }

  if (formError) {
    const message = getErrorMessage(formError);
    throw new Error(`[ERROR] Failed to fetch form data: ${message}`);
  }

  if (formInfoError) {
    const message = getErrorMessage(formInfoError);
    throw new Error(`[ERROR] Failed to fetch form info: ${message}`);
  }

  if (responseError) {
    const message = getErrorMessage(responseError);
    throw new Error(`[ERROR] Failed to fetch form response: ${message}`);
  }

  if (commentsError) {
    const message = getErrorMessage(commentsError);
    throw new Error(`[ERROR] Failed to fetch form comments: ${message}`);
  }

  // prevents createFormContainer from running on reload of dashboard when formData is undefined
  if (formData) {
    return (
      <CreateFormContainer
        responseData={responseData}
        formData={formData}
        commentsData={commentsData}
        formInfo={formInfo}
      />
    );
  }
  return null;
});

Evaluation.propTypes = {
  formConfig: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EvaluationContainer;
