import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useFormDispatch, useFormState } from '../../context/FormContext';
import { DropdownSuggest } from '../common';
import { STATUS } from '../../helpers/constants';
import useStyles from '../../styles/common/assignToStyles';

const AssignToField = ({ setReviewer, setError, error, disabled }) => {
  const { dispatch } = useFormDispatch();
  const {
    state: {
      formConfig: { status }
    }
  } = useFormState();

  const classes = useStyles();
  const formIsArchived = () => status === STATUS.ARCHIVED.NAME;

  const assignNextReviewer = reviewer => {
    setError('');
    if (reviewer) {
      dispatch({
        type: 'setAuditDataValue',
        payload: {
          assignedTo: reviewer.displayName,
          assignedToEmail: reviewer.mail || reviewer.userPrincipalName,
          assignedToEmployeeId: reviewer.employeeId || null
        }
      });
      setReviewer(reviewer);
    }
  };

  return (
    <>
      <Typography variant="h5" component="h5" className={classes.subtitle}>
        {formIsArchived() ? 'Send To' : 'Assign To'}
      </Typography>
      <Typography className={classes.subtext}>
        {formIsArchived()
          ? 'SEND A READ ONLY COPY OF  EVALUATION TO ANOTHER INDIVIDUAL; CLICK SEND'
          : 'ASSIGN EVALUATION TO NEXT INDIVIDUAL IN PROCESS; CLICK SEND'}
      </Typography>
      <div className={classes.assignDropdown}>
        <DropdownSuggest
          labelName={formIsArchived() ? 'Send To' : 'Assign To'}
          onChange={assignNextReviewer}
          errorMessage={error}
          disabled={disabled}
        />
      </div>
    </>
  );
};

AssignToField.defaultProps = {
  error: ''
};

AssignToField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setReviewer: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default AssignToField;
