import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../styles/common/formStyles';

const RatingScale = ({ sections }) => {
  const classes = useStyles();

  // use the first question to dictate the rating options for this form
  const renderRatingScale = () =>
    sections[1].sectionQuestions[0].answerOptions.map(answerOption => (
      <li key={answerOption} className={classes.rating}>
        {answerOption}
      </li>
    ));

  return (
    <div className={classes.ratingScaleContainer}>
      <div className={classes.ratingScale}>
        <h3>Rating Scale</h3>
        <ul>{renderRatingScale()}</ul>
      </div>
    </div>
  );
};

RatingScale.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default RatingScale;
