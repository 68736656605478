import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex'
  },
  bulkShareBtn: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 80,
    padding: 2,
    width: '20%',
    color: globalTheme.palette.neutral.contrast,
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },
  active: {
    backgroundColor: globalTheme.palette.primary.main
  },
  inactive: {
    backgroundColor: globalTheme.palette.neutral.main
  },
  hide: {
    display: 'none'
  },
  lineHeight: {
    lineHeight: 'initial'
  },
  progress: {
    color: globalTheme.palette.primary.main,
    alignSelf: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5% 5%',
    [theme.breakpoints.up('sm')]: {
      margin: '5% 10%'
    }
  },
  formControl: {
    display: 'flex'
  },
  viewSelectContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 80,
    width: '100%',
    '& .MuiFormControl-root': {
      width: '20%',
      [theme.breakpoints.down('sm')]: {
        width: '35%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '60%'
      }
    },
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    }
  },
  header: {
    color: globalTheme.palette.primary.main,
    fontSize: '2rem',
    fontWeight: '400',
    textAlign: 'center',
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem'
    }
  }
}));

export default useStyles;
