import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    width: 300,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    },
    '@media print': {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      '& span': {
        color: 'black',
        '&.MuiFormControlLabel-label.Mui-disabled': {
          color: 'black'
        }
      }
    },
    '& .MuiTypography-body1': {
      '@media print': {
        fontSize: '12px'
      }
    }
  },
  internshipRadioGroup: {
    width: 'auto',
    '&.MuiFormGroup-root': {
      display: 'block',
      paddingTop: 9,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: 10
      }
    },
    '@media print': {
      marginTop: 20,
      padding: 0,
      '& span': {
        color: 'black',
        '&.MuiFormControlLabel-label.Mui-disabled': {
          color: 'black'
        }
      }
    }
  },
  internReviewRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    '@media print': {
      flexDirection: 'row',
      '& span': {
        color: 'black',
        '&.MuiFormControlLabel-label.Mui-disabled': {
          color: 'black'
        }
      }
    }
  },
  attendanceRadioGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    '@media print': {
      width: 200,
      marginRight: 50,
      '& span': {
        color: 'black',
        '&.MuiFormControlLabel-label.Mui-disabled': {
          color: 'black'
        }
      }
    }
  }
}));

export default useStyles;
