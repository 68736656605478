import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { AuthContextProvider } from './context/AuthContext';
import { FormContextProvider } from './context/FormContext';
import { DashboardSearchContextProvider } from './context/DashboardSearchContext';
import { GoalsDashboardSearchContextProvider } from './context/GoalsDashboardSearchContext';
import { GoalContextProvider } from './context/GoalContext';
import ToastProvider from './context/ToastProvider';
import {
  Dashboard,
  EvaluationContainer,
  Login,
  PageNotFound,
  NewEval,
  GoalsDashboard,
  GoalContainer,
  NewGoal
} from './screens';
import PrivateRoute from './components/PrivateRoute';
import { ErrorBoundary } from './components/common';

import globalTheme from './styles/globalTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';

const App = () => {
  return (
    <MuiThemeProvider theme={globalTheme}>
      <ToastProvider>
        <AuthContextProvider>
          <FormContextProvider>
            <DashboardSearchContextProvider>
              <GoalsDashboardSearchContextProvider>
                <GoalContextProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router>
                      <div className="App">
                        <Switch>
                          <Route
                            exact
                            path="/"
                            render={() => (
                              <ErrorBoundary>
                                <Login />
                              </ErrorBoundary>
                            )}
                          />
                          <PrivateRoute
                            exact
                            path="/dashboard"
                            component={Dashboard}
                          />
                          <PrivateRoute
                            exact
                            path="/evaluation"
                            component={EvaluationContainer}
                          />
                          <PrivateRoute
                            exact
                            path="/newEvaluation"
                            component={NewEval}
                          />
                          <PrivateRoute
                            exact
                            path="/goalsDashboard"
                            component={GoalsDashboard}
                          />
                          <PrivateRoute
                            exact
                            path="/goal"
                            component={GoalContainer}
                          />
                          <PrivateRoute
                            exact
                            path="/newGoal"
                            component={NewGoal}
                          />
                          <PrivateRoute component={PageNotFound} />
                        </Switch>
                      </div>
                    </Router>
                  </MuiPickersUtilsProvider>
                </GoalContextProvider>
              </GoalsDashboardSearchContextProvider>
            </DashboardSearchContextProvider>
          </FormContextProvider>
        </AuthContextProvider>
      </ToastProvider>
    </MuiThemeProvider>
  );
};

export default App;
