import { createTheme } from '@material-ui/core/styles';

const lightAppleGreen = '#749116';
const peach = '#DF6C2C';
const blueSea = '#1975A6';
const raspberry = '#cd0000';

// Colors based on HP branding guidelines
const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#a6192e', // red
      mainAlpha: 'rgba(166, 25, 46, 0.4)'
    },
    secondary: {
      main: '#f2a900', // orange
      mainAlpha: 'rgba(242,169,0, 0.5)'
    },
    active: {
      main: '#35b018' // green
    },
    // black, white, grey
    neutral: {
      light: '#d0d3d4',
      lightAlpha: 'rgba(208, 211, 212, 0.3)',
      medLight: '#919394',
      medDark: '#68696a',
      main: '#2c2a29',
      dark: '#000',
      contrast: '#fff'
    },
    error: {
      main: raspberry
    },
    warning: {
      main: peach
    },
    info: {
      main: blueSea
    },
    success: {
      main: lightAppleGreen
    }
  }
});

export default globalTheme;
