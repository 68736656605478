import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Grid, Card, CardContent } from '@material-ui/core';

import { useAuth } from '../context/AuthContext';
import { LoadingSpinner } from '../components/common';
import useStyles from '../styles/common/loginStyles';
import hpLogo from '../assets/Perf-Plus_White_login.png';
import hpLogo2 from '../assets/hp_logo_6.png';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, login } = useAuth();
  const classes = useStyles();

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleLogin = async () => {
    setLoading(true);
    try {
      await login();
    } catch (error) {
      setLoading(false);
      throw new Error(`[ERROR] Failed to login: ${error}`);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10}>
        <Card className={classes.card} raised>
          <img
            src={hpLogo2}
            edge="start"
            className={classes.logo2}
            alt="logo2"
          />
          <img src={hpLogo} edge="start" className={classes.logo} alt="logo" />
          <CardContent>
            <Button
              type="submit"
              variant="contained"
              onClick={handleLogin}
              className={classes.submit}
              data-cy="login"
            >
              Sign In
            </Button>
          </CardContent>
          <span className={classes.version}>
            Version&nbsp;
            {process.env.REACT_APP_VERSION}
          </span>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
