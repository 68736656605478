import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Button, Typography } from '@material-ui/core';

import {
  FETCH_PPES_FOR_USER,
  FETCH_ALL_PPES,
  FETCH_EVALS_FOR_ROLE
} from '../../graphql/evalQueries';
import { useAuth } from '../../context/AuthContext';
import { useDashboardSearchState } from '../../context/DashboardSearchContext';
import { useFormState, useFormDispatch } from '../../context/FormContext';
import { STATUS, DASHBOARD_VIEWS, FORM_TYPE } from '../../helpers/constants';
import { LoadingSpinner, ModalScroll } from '../../components/common';
import { DuplicateModal } from '../../components/evaluations/FormModals';
import EvalsViewSelect from '../../components/evaluations/EvalsViewSelect';
import ReviewTable from '../../components/evaluations/ReviewTable';
import DashboardSearchBar from '../../components/evaluations/DashboardSearchBar';
import {
  dashboardTitleDisplay,
  truncateDate,
  removeDuplicates,
  getErrorMessage,
  idMatch
} from '../../helpers/helpers';
import { userIsPrimary, userIsManager } from '../../helpers/roleHelpers';
import useStyles from '../../styles/common/dashboardStyles';

// Calculates number of days an evaluation has been with its currently assigned employee
const getDaysAssigned = (assignedDate, status) => {
  const today = Date.parse(new Date());
  const dateAssigned = Date.parse(assignedDate);
  const timePassed = today - dateAssigned < 0 ? 0 : today - dateAssigned;
  const oneDay = 1000 * 60 * 60 * 24;
  const numDaysAssigned = Math.floor(timePassed / oneDay);
  const numDaysToDisplay =
    status === STATUS.ARCHIVED.NAME ? null : numDaysAssigned;
  return numDaysToDisplay;
};

const ppeDisplayNameById = id => {
  const [shortName] = Object.keys(FORM_TYPE)
    .filter(type => FORM_TYPE[type].ID === id)
    .map(type => FORM_TYPE[type].SHORT);
  return shortName;
};

// converts evaluation data into more useful format for the review table
const formatData = ppeData => {
  return (
    ppeData &&
    ppeData.map(ppe => {
      const {
        ppeType,
        ppeStatus: { displayName: status },
        createdOn,
        assignedOn
      } = ppe;
      return {
        ...ppe,
        ppeTypeId: ppeType.id,
        ppeTypeName: ppeDisplayNameById(ppeType.id),
        ppeStatus: status.toUpperCase(),
        createdOn: truncateDate(createdOn),
        daysAssigned: assignedOn
          ? getDaysAssigned(assignedOn, status.toUpperCase())
          : 0
      };
    })
  );
};

/* Queries for evaluations to display and passes data to ReviewTable */
const Dashboard = () => {
  const classes = useStyles();
  const { dispatch } = useFormDispatch();
  const {
    userData: { role, email, employeeId }
  } = useAuth();

  const [inactiveModalOpen, setInactiveModalOpen] = useState(false);
  const [inactiveEmployees, setInactiveEmployees] = useState([]);

  const {
    state: {
      dashboardSearchResults: { searchResultsArray },
      view
    }
  } = useDashboardSearchState();

  const {
    state: { existingPpeInfo }
  } = useFormState();

  // conditionals
  const viewIsAdmin = view === DASHBOARD_VIEWS.ADMIN.SHORT;
  const viewIsActive = view === DASHBOARD_VIEWS.ACTIVE.SHORT;
  const viewIsArchived = view === DASHBOARD_VIEWS.ARCHIVED.SHORT;

  const getQueryForView = () => {
    const queries = {
      [DASHBOARD_VIEWS.ACTIVE.SHORT]: FETCH_PPES_FOR_USER,
      [DASHBOARD_VIEWS.ARCHIVED.SHORT]: FETCH_PPES_FOR_USER,
      [DASHBOARD_VIEWS.ADMIN.SHORT]: FETCH_ALL_PPES,
      [DASHBOARD_VIEWS.BOARD.SHORT]: FETCH_EVALS_FOR_ROLE,
      [DASHBOARD_VIEWS.EXEC.SHORT]: FETCH_EVALS_FOR_ROLE,
      [DASHBOARD_VIEWS.MANAGER.SHORT]: FETCH_EVALS_FOR_ROLE
    };
    return queries[view];
  };

  // on Admin view, token is needed to fetch inactive users from Azure
  const getToken = () => {
    if (!viewIsAdmin) return '';
    return localStorage.getItem('accessToken') || '';
  };

  const { data, loading, error } = useQuery(getQueryForView(), {
    variables: { token: getToken() },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (viewIsAdmin && data && data.fetchAllPPEs) {
      const inactives = data.fetchAllPPEs
        .filter(ppe => ppe.inactive)
        .map(ppe => ({
          name: ppe.assignedTo,
          email: ppe.assignedToEmail
        }));

      const list = removeDuplicates(inactives);

      if (list.length) setInactiveEmployees(list);
    }
  }, [viewIsAdmin, data]);

  const getArchivedEvals = evals => {
    const archived = evals.filter(ev => ev.ppeStatus.id === STATUS.ARCHIVED.ID);
    // primary users and managers only see archived evaluations that have been assigned to them, or that are for them, or that they archived
    const archivedFor = archived.filter(
      ev =>
        ev.archivedAssigned ||
        idMatch(ev.evaluationForEmail, email) ||
        idMatch(ev.evaluationForEmployeeId, employeeId) ||
        ev.modifiedByEmail === email
    );
    return userIsPrimary(role) || userIsManager(role) ? archivedFor : archived;
  };

  const getUnarchivedEvals = evals => {
    return evals.filter(ev => ev.ppeStatus.id !== STATUS.ARCHIVED.ID);
  };

  const formattedData = () => {
    let dataToDisplay;

    if (searchResultsArray) {
      dataToDisplay = searchResultsArray;
    } else if (viewIsAdmin) {
      dataToDisplay = data.fetchAllPPEs ? data.fetchAllPPEs : {};
    } else if (viewIsActive || viewIsArchived) {
      dataToDisplay = data.fetchPPEsForUser ? data.fetchPPEsForUser : {};
    } else {
      dataToDisplay = data.fetchEvalsForRole ? data.fetchEvalsForRole : {};
    }

    if (viewIsArchived) return formatData(getArchivedEvals(dataToDisplay));
    if (viewIsActive) return formatData(getUnarchivedEvals(dataToDisplay));

    return formatData(dataToDisplay);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    const message = getErrorMessage(error);
    throw new Error(`[ERROR] Failed to fetch evaluations for user: ${message}`);
  }

  const handleClose = () => {
    dispatch({
      type: 'setExistingPpeInfo',
      payload: null
    });
  };

  const handleModalClose = () => setInactiveModalOpen(false);
  const handleModalOpen = () => setInactiveModalOpen(true);

  const getButtonClassName = () => {
    const btnClass =
      !viewIsAdmin || inactiveEmployees.length === 0 ? classes.hide : null;
    return `${classes.inactive} ${classes.bulkShareBtn} ${btnClass} ${classes.lineHeight}`;
  };

  const renderInactiveAssignees = () => {
    return inactiveEmployees.map((emp, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Typography key={i}>{`${emp.name} - ${emp.email}`}</Typography>
    ));
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <Button
            className={getButtonClassName()}
            variant="contained"
            onClick={handleModalOpen}
          >
            Inactive Reviewers
          </Button>
          <EvalsViewSelect view={view} />
        </div>
        <h1 className={classes.header}>
          {dashboardTitleDisplay(view, 'evals')}
        </h1>
        <DashboardSearchBar activeView={view} allPPEData={data} />
        <ReviewTable data={formattedData()} />
      </div>
      {existingPpeInfo && (
        <DuplicateModal
          close={handleClose}
          assignedTo={existingPpeInfo.assignedTo}
          assignedToEmail={existingPpeInfo.assignedToEmail}
          evaluationFor={existingPpeInfo.evaluationFor}
        />
      )}
      {inactiveModalOpen && (
        <ModalScroll
          isOpen
          handleClose={handleModalClose}
          modalTitle="Inactive Employees"
          scroll={inactiveEmployees.length > 10 ? 'paper' : undefined}
        >
          <Typography>
            The following inactive employees have evaluations assigned to them:
          </Typography>
          {renderInactiveAssignees()}
        </ModalScroll>
      )}
    </>
  );
};

export default Dashboard;
