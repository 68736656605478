import gql from 'graphql-tag';

// fetches matching evaluations to determine next eval date
export const FETCH_NEXT_EVAL_DATE = gql`
  query FetchNextEvalDateForUser($userName: String) {
    fetchNextEvalDateForUser(userName: $userName)
  }
`;

export const UPSERT_NEXT_EVAL_DATE_FOR_USER = gql`
  mutation UpsertNextEvalDateForUser(
    $userName: String
    $nextEvalDate: DateTime
  ) {
    upsertNextEvalDateForUser(
      userName: $userName
      nextEvalDate: $nextEvalDate
    ) {
      id
      nextEvalDate
      userName
    }
  }
`;
