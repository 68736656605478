import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

import useStyles from '../../styles/evals/radioButtonStyles';
import { toCamel } from '../../helpers/helpers';

const RadioButtons = ({
  options,
  onChange,
  defaultValue,
  disabled,
  classname
}) => {
  const classes = useStyles();

  const renderRadioOptions = () =>
    options.map(option => (
      <FormControlLabel
        key={option}
        value={option}
        aria-label={`rating option ${option}`}
        data-cy={toCamel(option)}
        control={<Radio color="default" />}
        label={option}
        labelPlacement="end"
        className={classes.radioText}
        disabled={disabled}
      />
    ));

  return (
    <RadioGroup
      className={`${classes[classname]}`}
      aria-label="rating"
      name="rating"
      value={defaultValue}
      onChange={onChange}
    >
      {options && renderRadioOptions()}
    </RadioGroup>
  );
};

RadioButtons.defaultProps = {
  options: [],
  defaultValue: ''
};

RadioButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  classname: PropTypes.string.isRequired
};

export default RadioButtons;
