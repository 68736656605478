import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useAuth } from '../../../context/AuthContext';
import GoalComment from './GoalComment';
import GoalPreviousComment from './GoalPreviousComment';
import useStyles from '../../../styles/goals/goalCommentsStyles';
import { idMatch } from '../../../helpers/helpers';

const GoalCommentSection = ({
  setCommentText,
  prevCommentsData,
  gatherCommentsForDeletion
}) => {
  const {
    userData: { email, employeeId }
  } = useAuth();
  const classes = useStyles();

  const canDeletePreviousComment = comment => {
    return (
      idMatch(comment.createdByEmail, email) ||
      idMatch(comment.createdByEmployeeId, employeeId)
    );
  };

  const renderPrevComments = () => {
    return (
      prevCommentsData &&
      prevCommentsData.map(comment => {
        const date = moment(comment.createdOn).format('L');
        return (
          <GoalPreviousComment
            key={comment.id}
            commentId={comment.id}
            comment={comment.comment}
            commentCreator={comment.createdBy}
            commentDate={date}
            checkBox={canDeletePreviousComment(comment)}
            gatherCommentsForDeletion={gatherCommentsForDeletion}
          />
        );
      })
    );
  };

  return (
    <div className={classes.commentContainer}>
      <p className={classes.commentHeader}>Comments</p>
      <GoalComment setCommentText={setCommentText} />
      {prevCommentsData && renderPrevComments()}
    </div>
  );
};

GoalCommentSection.defaultProps = {
  prevCommentsData: undefined
};

GoalCommentSection.propTypes = {
  setCommentText: PropTypes.func.isRequired,
  prevCommentsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  gatherCommentsForDeletion: PropTypes.func.isRequired
};

export default GoalCommentSection;
