import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 300,
    minWidth: 300,
    backgroundColor: globalTheme.palette.neutral.main,
    textAlign: 'center',
    opacity: 0.8,
    paddingTop: 10,
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
      maxHeight: 400
    }
  },
  submit: {
    color: 'white',
    backgroundColor: globalTheme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 29
    }
  },
  title: {
    color: 'white',
    fontSize: 20,
    [theme.breakpoints.up('sm')]: {
      fontSize: 28
    }
  },
  logo: {
    marginBottom: 20,
    marginTop: 45
  },
  logo2: {
    marginBottom: 45,
    marginRight: 180,
    marginTop: 13,
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 54,
      marginBottom: 10
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 1,
      marginBottom: 10
    }
  },
  version: {
    color: 'white',
    fontSize: 12,
    margin: 8,
    alignSelf: 'flex-end'
  }
}));

export default useStyles;
