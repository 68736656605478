import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  commentHeader: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal'
    },
    '@media print': {
      fontSize: '12px'
    }
  },
  commentHeaderSmaller: {
    fontSize: '16px',
    fontWeight: 'bold',
    '@media print': {
      fontSize: '12px'
    }
  },
  hiddenComment: {
    display: 'none'
  }
}));

export default useStyles;
