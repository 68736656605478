import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { TextField, IconButton, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Dropdown, DatePickerCalendar } from '../common';
import {
  GOAL_STATUS,
  GOAL_TRACK,
  GOAL_CATEGORY,
  GOAL_DASH_VIEWS
} from '../../helpers/constants';
import { FETCH_MATCHING_GOALS } from '../../graphql/goalQueries';
import {
  getDropdownSelectionOptions,
  getCategoryDashboardOptions
} from '../../helpers/goalFormHelpers';
import { getErrorMessage } from '../../helpers/helpers';
import {
  useGoalsDashboardSearchState,
  useGoalsDashboardSearchDispatch
} from '../../context/GoalsDashboardSearchContext';
import { useAuth } from '../../context/AuthContext';
import useStyles from '../../styles/goals/goalsDashboardSearchBarStyles';
import useSearchStyles from '../../styles/common/searchBarStyles';

const GoalsDashboardSearchBar = ({ activeView, allGoalsData }) => {
  const classes = useStyles();
  const searchClasses = useSearchStyles();
  const {
    state: {
      track,
      category,
      status,
      openSearchValue,
      startDate,
      endDateDisabled,
      endDate
    }
  } = useGoalsDashboardSearchState();
  const { goalsDashboardSearchDispatch } = useGoalsDashboardSearchDispatch();
  const { userData } = useAuth();

  const [clearCalendar, setClearCalendar] = useState({
    first: false,
    second: false
  });

  const [searching, setSearching] = useState(false);

  const [
    fetchMatchingGoals,
    { data: goalsSearchData, error: goalsSearchError }
  ] = useLazyQuery(FETCH_MATCHING_GOALS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => updateGoalsSearchData()
  });

  // view conditionals
  const viewIsMyGoals = activeView === GOAL_DASH_VIEWS.MY_GOALS;
  const viewIsSharedGoals = activeView === GOAL_DASH_VIEWS.SHARED_GOALS;

  // Dispatch search results to the context
  const updateGoalsSearchData = React.useCallback(() => {
    if (goalsSearchData) {
      goalsDashboardSearchDispatch({
        type: 'updateGoalsSearchResultsData',
        payload: goalsSearchData.fetchMatchingGoals
      });
    }
  }, [goalsSearchData, goalsDashboardSearchDispatch]);

  useEffect(() => {
    if (searching) updateGoalsSearchData();
  }, [searching, updateGoalsSearchData]);

  // alter dropdown text based on number of selections
  const chooseDropdownRenderValue = (
    selected,
    selectionType,
    selectionLength
  ) => {
    if (!selected.length || selected.length === selectionLength) {
      return `All ${selectionType}`;
    }
    if (selected.length === 1) {
      return selected;
    }
    return `Selected: ${selected.length}`;
  };

  const clearSearch = () => {
    document.getElementById('goalOpenSearch').value = null;

    if (clearCalendar.first) {
      setClearCalendar({
        first: false,
        second: true
      });
    } else {
      setClearCalendar({
        first: true,
        second: false
      });
    }

    goalsDashboardSearchDispatch({ type: 'clearSearch' });

    // re-fetch all goals if search is cleared
    refetchAllGoals();
  };

  const updateOpenSearch = event => {
    goalsDashboardSearchDispatch({
      type: 'setOpenSearch',
      payload: event.target.value
    });
  };

  const chooseGoalStatus = event => {
    goalsDashboardSearchDispatch({
      type: 'setStatus',
      payload: event.target.value
    });
  };

  const chooseGoalCategory = event => {
    goalsDashboardSearchDispatch({
      type: 'setCategory',
      payload: event.target.value
    });
  };

  const chooseGoalTrack = event => {
    goalsDashboardSearchDispatch({
      type: 'setTrack',
      payload: event.target.value
    });
  };

  const getDates = () => {
    const startDateSelected = document.getElementById('dueDateFrom').value;
    const endDateSelected = document.getElementById('dueDateTo').value;
    let start = null;
    let end = null;
    if (startDateSelected) {
      start = new Date(`${startDateSelected} 00:01:00.0000000`);
      if (endDateSelected) {
        end = new Date(`${endDateSelected} 23:59:59.9999999`);
      }
    }
    return { start, end };
  };

  const submitSearch = e => {
    if (e) e.preventDefault();

    const { start, end } = getDates();

    // prevent unnecessary fetching if no search params added
    if (
      (openSearchValue && !viewIsMyGoals) ||
      start ||
      end ||
      status.length ||
      category.length ||
      track.length
    ) {
      fetchMatchingGoals({
        variables: {
          dueDateSince: start,
          dueDateUntil: end,
          openSearch: openSearchValue,
          status,
          category,
          track,
          activeView
        }
      });
      // set this piece of state in order to trigger the updateSearchData cb function in case a user searches for the same thing after clearing and searching again
      setSearching(!searching);
    }
  };

  // Clear search on change of view - this will include clearing search when entering into a goal, search will not be retained
  // const useActiveViewSwitchEffect = fn => useEffect(fn, [activeView]);
  // useActiveViewSwitchEffect(clearSearch);

  if (goalsSearchError) {
    const message = getErrorMessage(goalsSearchError);
    throw new Error(`[ERROR] Failed to fetch all matching Goals: ${message}`);
  }

  const refetchAllGoals = () => {
    goalsDashboardSearchDispatch({
      type: 'updateGoalsSearchResultsData',
      payload:
        viewIsMyGoals || viewIsSharedGoals
          ? allGoalsData.fetchGoalsForUser
          : allGoalsData.fetchGoalsForRole
    });
  };

  return (
    <div
      className={classes.searchBarContainer}
      role="presentation"
      onKeyDown={e => (e.key === 'Enter' ? submitSearch(e) : null)}
    >
      <div className={searchClasses.openSearchContainer}>
        <TextField
          label="Last Name or Email"
          className={searchClasses.openSearch}
          id="goalOpenSearch"
          data-cy="nameOrEmail"
          onChange={updateOpenSearch}
          value={viewIsMyGoals ? userData.name : openSearchValue}
          disabled={viewIsMyGoals}
        />
      </div>
      <div className={classes.goalsDropDownsContainer}>
        <Dropdown
          labelName="Status"
          labelwidth={50}
          classname={classes.goalFormControl}
          data-cy="statusTypeDropdown"
          onChange={chooseGoalStatus}
          options={getDropdownSelectionOptions(GOAL_STATUS)}
          multiple
          multipleOptionsDefaultValue={status}
          renderValue={selected =>
            chooseDropdownRenderValue(
              selected,
              'statuses',
              Object.keys(GOAL_STATUS).length
            )
          }
          dropdownLocation="searchBar"
        />
        <Dropdown
          labelName="Track"
          labelwidth={50}
          classname={classes.goalFormControl}
          data-cy="trackTypeDropdown"
          onChange={chooseGoalTrack}
          options={getDropdownSelectionOptions(GOAL_TRACK)}
          multiple
          multipleOptionsDefaultValue={track}
          renderValue={selected =>
            chooseDropdownRenderValue(
              selected,
              'tracks',
              Object.keys(GOAL_TRACK).length
            )
          }
          dropdownLocation="searchBar"
        />
        <Dropdown
          labelName="Category"
          labelwidth={50}
          classname={classes.goalFormControl}
          data-cy="categoryTypeDropdown"
          onChange={chooseGoalCategory}
          options={getCategoryDashboardOptions(GOAL_CATEGORY)}
          multiple
          multipleOptionsDefaultValue={category}
          renderValue={selected =>
            chooseDropdownRenderValue(
              selected,
              'categories',
              Object.keys(GOAL_CATEGORY).length
            )
          }
          dropdownLocation="searchBar"
        />
      </div>
      <div className={classes.calendarContainer}>
        <DatePickerCalendar
          labelName="Due Date From"
          id="dueDateFrom"
          disabled={false}
          clearCalendar={clearCalendar}
          dispatch={goalsDashboardSearchDispatch}
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate.shortDate}
          isClearable
        />
        <DatePickerCalendar
          labelName="Due Date To"
          id="dueDateTo"
          disabled={endDateDisabled}
          minDate={startDate.shortDate}
          clearCalendar={clearCalendar}
          dispatch={goalsDashboardSearchDispatch}
          startDate={startDate}
          endDate={endDate}
          isClearable={false}
        />
      </div>
      <div className={searchClasses.buttonDiv}>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={submitSearch}
          data-cy="dashboardSearchBtn"
          className={searchClasses.searchIconContainer}
        >
          <SearchIcon className={searchClasses.searchIcon} />
        </IconButton>
        <Button
          variant="outlined"
          className={searchClasses.clearBtn}
          onClick={clearSearch}
          data-cy="clearSearchBtn"
        >
          Clear Search
        </Button>
      </div>
    </div>
  );
};

GoalsDashboardSearchBar.defaultProps = {
  allGoalsData: null
};

GoalsDashboardSearchBar.propTypes = {
  activeView: PropTypes.string.isRequired,
  allGoalsData: PropTypes.objectOf(PropTypes.any)
};

export default GoalsDashboardSearchBar;
