import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  success: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem'
  },
  error: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem'
  },
  warning: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem'
  },
  info: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem'
  }
}));

const ToastProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={2}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ToastProvider;
