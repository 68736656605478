import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { Modal } from '../common';

const DuplicateModal = ({
  close,
  evaluationFor,
  assignedTo,
  assignedToEmail
}) => {
  return (
    <Modal
      modalTitle="Duplicate"
      middleBtnText="Ok"
      middleBtnClick={close}
      isOpen
      handleClose={close}
    >
      <Typography>
        {`There is already an open evaluation for ${evaluationFor}`}
        <br />
        that is assigned to:
      </Typography>
      <Typography variant="h5">
        {`${assignedTo} ${assignedToEmail || ''}`}
      </Typography>
    </Modal>
  );
};

const SubmitModal = ({ close, action, reviewer }) => {
  return (
    <Modal
      modalTitle="Send Evaluation"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Send"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>Evaluation being assigned to:</Typography>
      <Typography variant="h5" data-cy="nextReviewerName">
        {reviewer.displayName}
      </Typography>
      <Typography variant="h5" data-cy="nextReviewerEmail" noWrap>
        {reviewer.mail}
      </Typography>
    </Modal>
  );
};

const ArchiveModal = ({ close, action }) => {
  return (
    <Modal
      modalTitle="Archive Evaluation"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Archive"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>
        Evaluation will be
        <b> locked </b>
        and stored for employee records.
      </Typography>
      <Typography>
        It will
        <b> no longer be available </b>
        to make any changes.
      </Typography>
    </Modal>
  );
};

const CancelModal = ({ close, action }) => {
  return (
    <Modal
      modalTitle="Discard Changes"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Discard"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>All unsaved changes will be lost.</Typography>
    </Modal>
  );
};

const DeleteModal = ({ close, action }) => {
  return (
    <Modal
      modalTitle="Delete Evaluation"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Delete"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>This evaluation will be permanently deleted.</Typography>
      <Typography>This action cannot be undone.</Typography>
    </Modal>
  );
};

const ErrorModal = ({ close, error }) => {
  return (
    <Modal middleBtnText="Ok" middleBtnClick={close} isOpen handleClose={close}>
      <Typography>
        {errorModalMessage(error)}
        <br />
        PLEASE TRY AGAIN.
      </Typography>
    </Modal>
  );
};

const errorModalMessage = error => {
  const messages = {
    SAVE: 'THERE WAS AN ERROR SAVING THIS EVALUATION.',
    SEND: 'THERE WAS AN ERROR SENDING THIS EVALUATION.',
    ARCHIVE: 'THERE WAS AN ERROR ARCHIVING THIS EVALUATION.',
    PUBLISH: 'THERE WAS AN ERROR PUBLISHING THIS EVALUATION.',
    DELETE: 'THERE WAS AN ERROR DELETING THIS EVALUATION.'
  };
  return messages[error];
};

DuplicateModal.defaultProps = {
  assignedToEmail: ''
};

DuplicateModal.propTypes = {
  close: PropTypes.func.isRequired,
  evaluationFor: PropTypes.string.isRequired,
  assignedTo: PropTypes.string.isRequired,
  assignedToEmail: PropTypes.string
};

SubmitModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  reviewer: PropTypes.objectOf(PropTypes.any).isRequired
};

ArchiveModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

CancelModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

DeleteModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

ErrorModal.propTypes = {
  close: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};

export {
  DuplicateModal,
  SubmitModal,
  ArchiveModal,
  CancelModal,
  DeleteModal,
  ErrorModal
};
