import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://4c14274a18ea4575abc8e5fb04f81029@o322387.ingest.sentry.io/4504753104289792',
    environment: process.env.APP_ENV,
    tracesSampleRate: 0
  });
}

const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}`,
  credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('userData');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink)
});

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
