import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { Modal } from '../common';
import useStyles from '../../styles/common/modalStyles';

const CalendarEventModal = ({ close, action }) => {
  return (
    <Modal
      modalTitle="Create Calendar Event"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Create Event"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>
        This will create an event on your Outlook calendar.
      </Typography>
    </Modal>
  );
};

const CancelModal = ({ close, action }) => {
  return (
    <Modal
      modalTitle="Discard Changes"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Discard"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>All unsaved changes will be lost.</Typography>
    </Modal>
  );
};

const DeleteModal = ({ close, action }) => {
  const classes = useStyles();

  return (
    <Modal
      modalTitle="Delete Goal"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Delete"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography className={classes.deleteModalText}>
        This goal will be permanently deleted.
      </Typography>
      <Typography className={classes.deleteModalText}>
        This action cannot be undone.
      </Typography>
    </Modal>
  );
};

const ErrorModal = ({ close, error }) => {
  return (
    <Modal middleBtnText="Ok" middleBtnClick={close} isOpen handleClose={close}>
      <Typography>
        {errorModalMessage(error)}
        <br />
        PLEASE TRY AGAIN.
      </Typography>
    </Modal>
  );
};

const UnshareModal = ({ close, action }) => {
  return (
    <Modal
      modalTitle="Unshare Goal"
      leftBtnText="Go Back"
      leftBtnClick={close}
      rightBtnText="Unshare"
      rightBtnClick={action}
      isOpen
      handleClose={close}
    >
      <Typography>
        You will no longer have access to this goal from your
        <br />
        shared goals dashboard.
      </Typography>
    </Modal>
  );
};

const errorModalMessage = error => {
  const messages = {
    SAVE: 'THERE WAS AN ERROR SAVING THIS GOAL.',
    SHARE: 'THERE WAS AN ERROR SHARING THIS GOAL.',
    DELETE: 'THERE WAS AN ERROR DELETING THIS GOAL.',
    UNSHARE: 'THERE WAS AN ERROR UNSHARING THIS GOAL.'
  };
  return messages[error];
};

CalendarEventModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

CancelModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

ErrorModal.propTypes = {
  close: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};

DeleteModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

UnshareModal.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

export {
  CalendarEventModal,
  CancelModal,
  DeleteModal,
  ErrorModal,
  UnshareModal
};
