import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const GoalTitleAndDescription = ({
  setGoalTitleOrDescription,
  initialValue,
  disabled,
  label,
  idTag
}) => {
  const ref = useRef();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const { current } = ref;

    const handleBlur = () => {
      setGoalTitleOrDescription(idTag, value);
    };

    if (current) current.addEventListener('blur', handleBlur);

    return () => {
      if (current) current.removeEventListener('blur', handleBlur);
    };
  });

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <TextField
      id={idTag}
      data-cy={idTag}
      variant="outlined"
      onChange={handleChange}
      label={label}
      multiline
      defaultValue={initialValue}
      disabled={disabled}
      inputRef={ref}
    />
  );
};

GoalTitleAndDescription.defaultProps = {
  initialValue: null
};

GoalTitleAndDescription.propTypes = {
  setGoalTitleOrDescription: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  idTag: PropTypes.string.isRequired
};

export default GoalTitleAndDescription;
