import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    paddingBottom: 0,
    textAlign: 'center'
  },
  progress: {
    color: globalTheme.palette.primary.main
  }
}));

export default useStyles;
