import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flow-root',
    height: 400,
    backgroundColor: globalTheme.palette.primary.main,
    borderRadius: 0,
    color: globalTheme.palette.neutral.contrast,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 200
    },
    '& h3': {
      alignSelf: 'center',
      marginTop: 165,
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
        marginTop: 95
      }
    },
    '& h1': {
      alignSelf: 'center',
      marginTop: 200,
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
        marginTop: 125
      }
    }
  },
  subtitle: {
    fontSize: '1.8rem',
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  wrapper: {
    textAlign: 'center'
  }
}));

export default useStyles;
