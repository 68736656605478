import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import { useGoalState } from '../../../context/GoalContext';
import { MAX_COMMENT_CHARS } from '../../../helpers/constants';
import { getMaxCommentLengthText } from '../../../helpers/helpers';
import useStyles from '../../../styles/goals/goalPrevCommentsStyles';

const GoalComment = ({ setCommentText }) => {
  const {
    state: { goalFormConfig }
  } = useGoalState();
  const { commentText } = goalFormConfig;
  const [value, setValue] = useState(commentText);
  const [commentLength, setCommentLength] = useState();
  const classes = useStyles();
  const ref = useRef();

  useEffect(() => {
    const { current } = ref;

    const handleBlur = () => {
      setCommentText(value);
    };

    if (current) current.addEventListener('blur', handleBlur);

    return () => {
      if (current) current.removeEventListener('blur', handleBlur);
    };
  });

  const handleChange = event => {
    const newComment = event.target.value;

    setValue(newComment);
    setCommentLength(newComment.length || 0);
  };

  return (
    <div className={classes.textField}>
      <TextField
        id="goalCommentField"
        variant="outlined"
        label="ADD COMMENTS HERE"
        margin="normal"
        multiline
        fullWidth
        onChange={handleChange}
        disabled={false}
        value={value}
        inputRef={ref}
        inputProps={{
          maxLength: MAX_COMMENT_CHARS
        }}
        helperText={getMaxCommentLengthText(commentLength)}
      />
    </div>
  );
};

GoalComment.propTypes = {
  setCommentText: PropTypes.func.isRequired
};

export default GoalComment;
