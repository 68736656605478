/* Create application with Microsoft's authentication library */

import { UserAgentApplication } from 'msal';

import config from './azureConfig';

const userAgentApplication = new UserAgentApplication({
  auth: {
    clientId: config.clientId,
    authority: config.authority
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
});

export default userAgentApplication;
