import classNames from 'classnames';

import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: theme.spacing(10),
    width: 'auto',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    borderRadius: 16,
  },
}));

const StyledButtonPrimary= ({
  label,
  color = 'primary',
  variant = 'contained',
  onClick,
  className,
  icon,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      data-testid="button-primary"
      color={color}
      variant={variant}
      onClick={onClick}
      startIcon={icon || undefined}
      className={classNames(classes.button, className)}
      {...rest}
    >
      {label}
    </Button>
  );
};

StyledButtonPrimary.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.any,
};

export default StyledButtonPrimary;
