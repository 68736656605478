import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from '../../styles/common/modalStyles';

/* A simple modal with scrollable conent and no button functionality */
const ModalScroll = ({ modalTitle, isOpen, handleClose, children, scroll }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.root}
      fullWidth
      scroll={scroll}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {modalTitle}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={`${classes.dialogContent} ${classes.extraSpace}`}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

ModalScroll.defaultProps = {
  modalTitle: '',
  scroll: undefined
};

ModalScroll.propTypes = {
  modalTitle: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  scroll: PropTypes.string
};

export default ModalScroll;
