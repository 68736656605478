import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import { useFormState } from '../../../context/FormContext';
import { MAX_COMMENT_CHARS } from '../../../helpers/constants';
import { getMaxCommentLengthText } from '../../../helpers/helpers';
import useStyles from '../../../styles/evals/previousCommentsStyles';

/* Section displaying comments previously added to a section */
const PreviousComment = React.memo(
  ({
    checkBox,
    classname,
    comment,
    commentData,
    disabled,
    sectionId,
    setCommentsToBeDeleted,
    updateCommentValues,
    updatedComments
  }) => {
    const { commentId, commentCreator, createdOn } = commentData;

    const {
      state: { printVersion }
    } = useFormState();

    const classes = useStyles();
    const ref = useRef();

    const getInitialComment = () => {
      // if comment has been edited, use edited version
      const editedComment = updatedComments.find(updatedComment => {
        return updatedComment.id === commentId;
      });

      return editedComment ? editedComment.comment : comment;
    };

    const initialComment = getInitialComment();
    const [value, setValue] = useState(initialComment);
    const [commentLength, setCommentLength] = useState();
    const [checked, setChecked] = useState(false);

    // Use effect is necessary because the TextField component does not re-render when props change
    useEffect(() => {
      setValue(initialComment);
    }, [initialComment]);

    useEffect(() => {
      const { current } = ref;

      const handleBlur = () => {
        if (value !== initialComment) {
          updateCommentValues(value, sectionId, commentId, createdOn);
        }
        const commentIsDeleted = value === '' || value === undefined;
        setChecked(commentIsDeleted);
        setCommentsToBeDeleted(commentId, commentIsDeleted);
      };
      if (current) current.addEventListener('blur', handleBlur);

      return () => {
        if (current) current.removeEventListener('blur', handleBlur);
      };
    });

    const handleChange = event => {
      const newComment = event.target.value;

      setValue(newComment);
      setCommentLength(newComment.length || 0);
    };

    const handleCheckChange = () => {
      // this ensures that an empty comment will always be checked, the user can't un-check it
      if (value !== '') {
        setChecked(!checked);
        setCommentsToBeDeleted(commentId, !checked);
      }
    };

    return (
      <div className={`${classes.commentDetails} ${classes[classname]}`}>
        <p className={classes.commentCreator}>{commentCreator}</p>
        <div className={classes.textFieldContainer}>
          {printVersion ? (
            <p className={classes.textField}>{value}</p>
          ) : (
            <TextField
              value={value}
              multiline
              fullWidth
              onChange={handleChange}
              disabled={disabled}
              className={classes.textField}
              id="commentField"
              inputRef={ref}
              inputProps={{
                maxLength: MAX_COMMENT_CHARS
              }}
              helperText={!disabled && getMaxCommentLengthText(commentLength)}
            />
          )}
        </div>
        {checkBox && (
          <div
            className={
              checked ? classes.checkBoxContainer : classes.uncheckedContainer
            }
          >
            <FormControlLabel
              control={
                <Checkbox // eslint-disable-line
                  checked={checked}
                  onChange={() => handleCheckChange()}
                  value="Delete Comment"
                  className={classes.checkBox}
                  data-cy="deleteCheckbox"
                />
              }
              label="Delete Comment"
            />
          </div>
        )}
      </div>
    );
  }
);

PreviousComment.defaultProps = {
  classname: ''
};

PreviousComment.propTypes = {
  checkBox: PropTypes.bool.isRequired,
  classname: PropTypes.string,
  comment: PropTypes.string.isRequired,
  commentData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  sectionId: PropTypes.number.isRequired,
  setCommentsToBeDeleted: PropTypes.func.isRequired,
  updateCommentValues: PropTypes.func.isRequired,
  updatedComments: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PreviousComment;
