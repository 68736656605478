import React from 'react';

import { PageError } from '../components/common';

/* Component for displaying 404 errors */
const PageNotFound = () => {
  const title = "Oops! That page can't be found.";
  const subtitle =
    'It looks like nothing was found at this location. Maybe try one of the links above?';
  return <PageError title={title} subtitle={subtitle} notFoundPage />;
};

export default PageNotFound;
