import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { FormControl, Button } from '@material-ui/core';
import moment from 'moment';

import { useFormState, useFormDispatch } from '../../context/FormContext';
import { useAuth } from '../../context/AuthContext';
import {
  FETCH_ALL_FORM_TYPES,
  FETCH_LAST_EVAL_DATE,
  CHECK_FOR_EXISTING_PPE
} from '../../graphql/evalQueries';
import { FETCH_NEXT_EVAL_DATE } from '../../graphql/nextEval';
import { GET_USER_ROLES } from '../../graphql/goalQueries';
import { Dropdown, DropdownSuggest, LoadingSpinner, Modal } from '../common';
import useStyles from '../../styles/common/initiateEvalStyles';
import { getErrorMessage } from '../../helpers/helpers';

// Retrieve display names for each form type
const getFormTypes = ({ fetchAllFormTypes }) =>
  fetchAllFormTypes
    .filter(formType => formType.isActive)
    .map(formData => formData.displayName)
    .sort();

/* Component for popup modal for choosing employee and form type */
const InitiateEval = ({ isOpen, closeModal, renderType }) => {
  const {
    state: {
      formConfig: { formType },
      auditData: { evaluationFor, evaluationForEmail, evaluationForEmployeeId }
    }
  } = useFormState();
  const { dispatch } = useFormDispatch();
  const { refreshLogin } = useAuth();

  const history = useHistory();
  const client = useApolloClient();
  const classes = useStyles();

  const { data, loading, error } = useQuery(FETCH_ALL_FORM_TYPES);
  const { data: lastEvalData } = useQuery(FETCH_LAST_EVAL_DATE, {
    variables: { evaluationFor, evaluationForEmail, evaluationForEmployeeId },
    skip: !evaluationFor,
    fetchPolicy: 'no-cache'
  });
  const { data: nextEvalData } = useQuery(FETCH_NEXT_EVAL_DATE, {
    variables: { userName: evaluationForEmail },
    skip: !evaluationFor,
    fetchPolicy: 'no-cache'
  });
  const [
    existingPpeCheck,
    { data: existingPpeData, loading: existingLoading }
  ] = useLazyQuery(CHECK_FOR_EXISTING_PPE, { fetchPolicy: 'no-cache' });

  // check whether this user already has an existing evaluation
  useEffect(() => {
    if (evaluationForEmail && formType) {
      const checkForEval = async () => {
        existingPpeCheck({
          variables: {
            userEmail: evaluationForEmail,
            userId: evaluationForEmployeeId
          }
        });
      };
      checkForEval();
    }
  }, [
    evaluationForEmail,
    evaluationForEmployeeId,
    refreshLogin,
    formType,
    existingPpeCheck
  ]);

  if (loading) return <LoadingSpinner />;
  if (error) {
    const message = getErrorMessage(error);
    throw new Error(`[ERROR] Failed to fetch all form types: ${message}`);
  }

  // not all employees have these fields
  const checkForEmployeeId = user => user.employeeId || null;
  const checkForEmployeeJobNumber = user => user.jobNumber || null;
  const checkForEmployeeDistrictNumber = user => user.districtNumber || null;
  const checkForEmployeeHireDate = user => user.hireDate || null;

  // Set the employee being evaluated
  const chooseEmployee = user => {
    dispatch({
      type: 'setAuditDataValue',
      payload: {
        evaluationFor: user ? user.displayName : '',
        evaluationForEmail: user ? user.mail || user.userPrincipalName : '',
        jobTitle: user ? user.jobTitle : '',
        evaluationForEmployeeId: user ? checkForEmployeeId(user) : null,
        evaluationForJobNumber: user ? checkForEmployeeJobNumber(user) : '',
        evaluationForDistrictNumber: user
          ? checkForEmployeeDistrictNumber(user)
          : '',
        evaluationForHireDate: user ? checkForEmployeeHireDate(user) : ''
      }
    });
  };

  // Set the form type
  const chooseFormType = event => {
    const [chosenFormType] = data.fetchAllFormTypes.filter(
      formInfo => formInfo.displayName === event.target.value
    );
    dispatch({
      type: 'setFormConfig',
      payload: {
        formType: chosenFormType ? event.target.value : '',
        formTypeId: chosenFormType ? chosenFormType.id : 0
      }
    });
  };

  // get date of this user's previous evaluation
  const getLastEvalDate = ({ fetchLastEvalDate }) => {
    if (fetchLastEvalDate.length) {
      const date = fetchLastEvalDate[0].modifiedOn;
      return moment(date).format('L');
    }
    return null;
  };

  // get date of this user's next evaluation
  const getNextEvalDate = ({ fetchNextEvalDateForUser }) => {
    if (fetchNextEvalDateForUser) {
      const date = fetchNextEvalDateForUser;
      return moment(date).format('L');
    }
    return null;
  };

  // fetch the azure role of the user the evaluation is for
  const fetchUserRole = async () => {
    const fetchRole = (token, email) => {
      return client.query({
        query: GET_USER_ROLES,
        variables: { token, email }
      });
    };

    const token = localStorage.getItem('accessToken');

    try {
      const {
        data: { getUserRoles }
      } = await fetchRole(token, evaluationForEmail);

      dispatch({
        type: 'setAuditDataValue',
        payload: { evaluationForRole: getUserRoles }
      });
    } catch (err) {
      const message = getErrorMessage(err);
      throw new Error(`[ERROR] Failed to fetch user role: ${message}`);
    }
  };

  const handleEvalCreation = () => {
    if (!existingLoading) {
      if (existingPpeData.checkForExistingPPE.length) {
        existingEvalFound();
      } else {
        fetchUserRole();
        startEvaluation();
      }
    }
  };

  // an evaluation already exists for this user, so prevent creation of a new one
  const existingEvalFound = () => {
    const { checkForExistingPPE } = existingPpeData;
    const ppeInfo = checkForExistingPPE[0];
    dispatch({
      type: 'setExistingPpeInfo',
      payload: ppeInfo
    });
    closeModal();
    redirectToDashboard();
  };

  // Empty out form state and navigate to the evaluation
  const startEvaluation = () => {
    dispatch({
      type: 'setAuditDataValue',
      payload: {
        assignedTo: null,
        assignedToEmail: null,
        assignedToEmployeeId: null,
        lastEvalDate: lastEvalData ? getLastEvalDate(lastEvalData) : null,
        nextEvalDate: nextEvalData ? getNextEvalDate(nextEvalData) : null
      }
    });
    dispatch({ type: 'setFormConfig', payload: { ppeId: null, formType: '' } });
    closeModal();
    history.push('/evaluation');
  };

  const redirectToDashboard = () => {
    history.push('/dashboard');
  };

  // render a modal for user on desktop
  const renderModalVersion = () => {
    return (
      <Modal
        modalTitle="Start New Evaluation"
        leftBtnText="Cancel"
        leftBtnClick={closeModal}
        rightBtnText="Create"
        rightBtnClick={handleEvalCreation}
        isOpen={isOpen}
        handleClose={closeModal}
        disabled={!evaluationFor || !formType}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <DropdownSuggest
            labelName="Evaluation For"
            onChange={chooseEmployee}
            errorMessage=""
            disabled={false}
          />
        </FormControl>
        <Dropdown
          options={getFormTypes(data)}
          onChange={chooseFormType}
          labelName="Form Type"
          labelwidth={80}
          defaultValue={formType}
          classname={classes.formControl}
          dropdownLocation="initiateEval"
        />
      </Modal>
    );
  };

  // render full page for use on mobile
  const renderPageVersion = () => {
    return (
      <div className={classes.newEvalPageContainer}>
        <h2 className={classes.pageVersionTitle}>Start New Evaluation</h2>
        <FormControl variant="outlined" className={classes.pageFormControl}>
          <DropdownSuggest
            labelName="Evaluation For"
            onChange={chooseEmployee}
            errorMessage=""
          />
        </FormControl>

        <Dropdown
          options={getFormTypes(data)}
          onChange={chooseFormType}
          labelName="Form Type"
          labelwidth={80}
          defaultValue={formType}
          classname={classes.pageFormControl}
          disabled={false}
          dropdownLocation="initiateEval"
        />
        <div className={classes.actionButtons}>
          <Button
            className={`${classes.button} ${classes.negativeButton}`}
            onClick={redirectToDashboard}
            variant="outlined"
            data-cy="cancelNewEval"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.button} ${classes.positiveButton}`}
            onClick={handleEvalCreation}
            disabled={!evaluationFor || !formType}
            data-cy="createNewEval"
          >
            Create
          </Button>
        </div>
      </div>
    );
  };

  return renderType === 'modal' ? renderModalVersion() : renderPageVersion();
};

InitiateEval.defaultProps = {
  isOpen: false,
  closeModal: () => {}
};

InitiateEval.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  renderType: PropTypes.string.isRequired
};

export default InitiateEval;
