import { createTheme } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

export const getEmptyMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTableBody: {
        emptyTitle: {
          '@media (max-width: 960px)': {
            display: 'flex',
            justifyContent: 'center',
            width: '180%'
          }
        }
      },
      MuiTablePagination: {
        toolbar: {
          '@media (max-width: 960px)': {
            flexWrap: 'wrap',
            justifyContent: 'center'
          }
        },
        actions: {
          '@media (max-width: 960px)': {
            marginLeft: 0
          }
        }
      },
      MUIDataTableBodyCell: {
        responsiveStacked: {
          '@media (max-width: 960px)': {
            width: 'calc(50%)'
          }
        },
        cellStacked: {
          '@media (max-width: 960px)': {
            display: 'none'
          }
        }
      }
    }
  });

export const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          fontSize: '1.1rem',
          fontWeight: 'bold'
        }
      },
      MUIDataTableBodyRow: {
        responsiveStacked: {
          '@media (max-width: 960px)': {
            borderTop: 'solid 30px #e9e9e9'
          }
        }
      },
      MUIDataTableBodyCell: {
        responsiveStacked: {
          '@media (max-width: 960px)': {
            width: 'calc(50%)',
            fontSize: '1rem',
            height: 32
          }
        },
        cellStacked: {
          '@media (max-width: 960px)': {
            marginRight: 13,
            fontWeight: 'bold',
            fontSize: '1rem',
            whiteSpace: 'normal',
            height: 32
          }
        }
      },
      MUIDataTableSelectCell: {
        checkboxRoot: {
          '&$checked': {
            color: globalTheme.palette.primary.main
          }
        }
      },
      MuiTableCell: {
        root: {
          '@media (max-width: 960px)': {
            '&:last-child > section': {
              width: '3rem',
              height: '3rem',
              display: 'flex',
              '& svg': {
                width: '2.2rem',
                height: '2.2rem',
                alignSelf: 'center'
              }
            },
            '&:nth-last-child(-n+2)': {
              height: '3rem',
              lineHeight: '3rem'
            }
          }
        }
      },
      MuiTablePagination: {
        toolbar: {
          '@media (max-width: 960px)': {
            flexWrap: 'wrap',
            justifyContent: 'center'
          }
        },
        actions: {
          '@media (max-width: 960px)': {
            marginLeft: 0
          }
        }
      }
    }
  });

export const inactiveRowHighlight = () => {
  return {
    style: { backgroundColor: globalTheme.palette.primary.mainAlpha }
  };
};

export const inactiveCellHighlight = () => {
  return {
    style: {
      color: globalTheme.palette.primary.main,
      fontWeight: 'bold'
    }
  };
};

export const goalFlagHighlight = () => {
  return {
    style: { backgroundColor: globalTheme.palette.secondary.mainAlpha }
  };
};
