import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormDispatch } from '../../context/FormContext';
import Form from './Form';

const CreateFormContainer = props => {
  const { dispatch } = useFormDispatch();

  return <CreateForm dispatch={dispatch} {...props} />;
};

/* Formats retrieved form data, adds it to the app's context state and renders the Form */

const CreateForm = ({
  dispatch,
  responseData,
  formData,
  formInfo,
  commentsData
}) => {
  useEffect(() => {
    const createFormResponses = sections => {
      const { fetchPPEResponses: answers } = responseData;

      // create a two-dimensional array representing sections & questions
      const responseArray = sections.map(section => {
        return [
          ...section.sectionQuestions.map(question => {
            return {
              questionText: question.questionText
            };
          })
        ];
      });

      // populate the array with existing answers
      answers.forEach(answer => {
        responseArray[answer.sectionId][answer.questionId] = {
          id: answer.id,
          questionId: answer.questionId,
          sectionId: answer.sectionId,
          employeeResponse: answer.employeeResponse || '',
          reviewerResponse: answer.reviewerResponse || '',
          ...responseArray[answer.sectionId][answer.questionId]
        };
      });

      return responseArray;
    };

    const { fetchPPEComments: comments } = commentsData;

    const parsedFormStructure = JSON.parse(
      formData.fetchFormStructure.attributes
    );

    const formResponses = createFormResponses(parsedFormStructure.sections);

    // Add formatted form data to context state
    dispatch({ type: 'setFormStructure', payload: parsedFormStructure });
    dispatch({ type: 'setResponses', payload: formResponses });
    dispatch({ type: 'setComments', payload: comments });
  }, [dispatch, formData, responseData, commentsData]);

  const {
    fetchFormStructure: { processType }
  } = formData;

  const {
    fetchPpeInfo: { modifiedOn, modifiedBy, modifiedByEmail }
  } = formInfo;

  const ppeModificationInfo = {
    modifiedOn,
    modifiedBy,
    modifiedByEmail
  };

  return (
    <Form processType={processType} ppeModificationInfo={ppeModificationInfo} />
  );
};

CreateForm.defaultProps = {
  responseData: {
    fetchPPEResponses: []
  },
  commentsData: {
    fetchPPEComments: []
  },
  formInfo: {
    fetchPpeInfo: {}
  }
};

CreateForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  responseData: PropTypes.shape({
    fetchPPEResponses: PropTypes.any
  }),
  commentsData: PropTypes.shape({
    fetchPPEComments: PropTypes.any
  }),
  formData: PropTypes.shape({
    fetchFormStructure: PropTypes.any
  }).isRequired,
  formInfo: PropTypes.shape({
    fetchPpeInfo: PropTypes.any
  })
};

export default CreateFormContainer;
