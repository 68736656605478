import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useFormState } from '../../context/FormContext';
import { STATUS } from '../../helpers/constants';
import useStyles from '../../styles/common/formStyles';

// displays basic form info as header on archived evaluations
const StatusBanner = ({ info, modifiedOn }) => {
  const { state } = useFormState();
  const { formConfig } = state;
  const { status } = formConfig;
  const classes = useStyles();
  const { modifiedBy, modifiedByEmail } = info;

  const formIsClosed = () => status === STATUS.ARCHIVED.NAME;

  const renderStatusBanner = () => {
    const dateObj = moment(modifiedOn);
    const date = dateObj.isValid() ? dateObj.format('L') : null;

    let archiveDetails;

    if (formIsClosed()) {
      archiveDetails = (
        <>
          <p className={classes.archiveDetails}>
            {date ? `Archived Date: ${date}` : `Archived Evaluation`}
          </p>
          <p className={classes.archiveDetails}>
            {modifiedBy &&
              modifiedByEmail &&
              `Archived By: ${modifiedBy} ${modifiedByEmail}`}
          </p>
        </>
      );
    }

    return archiveDetails;
  };

  return <div className={classes.statusBanner}>{renderStatusBanner()}</div>;
};

StatusBanner.propTypes = {
  info: PropTypes.objectOf(PropTypes.any).isRequired
};

export default StatusBanner;
