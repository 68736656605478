import React from 'react';
import { Link } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { useFormDispatch } from '../context/FormContext';
import useStyles from '../styles/common/formStyles';

const PrintPDFButton = ({ closed }) => {
  const { dispatch } = useFormDispatch();
  const classes = useStyles();

  const printPdf = async () => {
    await dispatch({
      type: 'setPrintVersion',
      payload: true
    });
    window.print();
    await dispatch({
      type: 'setPrintVersion',
      payload: false
    });
  };

  return (
    <div className={classes.actionLinkContainer}>
      <Print className={classes.actionIcon} />
      <Link
        component="button"
        variant="body2"
        data-cy="printPdfBtn"
        onClick={printPdf}
        className={
          closed
            ? `${classes.pdfLinkClosedView} ${classes.actionLink}`
            : classes.actionLink
        }
      >
        Print Formatted PDF
      </Link>
    </div>
  );
};

PrintPDFButton.propTypes = {
  closed: PropTypes.bool.isRequired
};

export default PrintPDFButton;
