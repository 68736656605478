import React from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../../context/AuthContext';
import { useGoalDispatch } from '../../context/GoalContext';
import ViewSelector from '../common/ViewSelector';
import { ROLE, GOAL_DASH_VIEWS } from '../../helpers/constants';
import { userIsPrimary, userIsManager } from '../../helpers/roleHelpers';

/* Dropdown for selecting between different goals dashboard views */
const GoalsViewSelect = ({ view }) => {
  const {
    userData: { role, jobNumber }
  } = useAuth();

  const { goalDispatch } = useGoalDispatch();

  const getViewOptions = () => {
    const baseViews = [GOAL_DASH_VIEWS.MY_GOALS, GOAL_DASH_VIEWS.SHARED_GOALS];

    const extraViews = {
      [ROLE.ADMIN]: GOAL_DASH_VIEWS.BOARD_VIEW,
      [ROLE.BOARD]: GOAL_DASH_VIEWS.BOARD_VIEW,
      [ROLE.EXEC]: GOAL_DASH_VIEWS.EXECUTIVE_VIEW,
      [ROLE.MANAGER]: GOAL_DASH_VIEWS.MANAGER_VIEW
    };

    // primary users only see base views
    if (!role || userIsPrimary(role)) return baseViews;

    // Managers with job number prefix 'OH' should see managerViews, unless stakeholders want original functionality of it being hidden.
    // if (userIsManager(role) && jobNumber.startsWith('OH')) return baseViews;

    // other users see additional view based on their role
    return [...baseViews, extraViews[role]];
  };

  const setViewSelection = e => {
    goalDispatch({
      type: 'setView',
      payload: e.target.value
    });
  };

  return (
    <ViewSelector
      view={view}
      setViewSelection={setViewSelection}
      viewOptions={getViewOptions()}
    />
  );
};

GoalsViewSelect.propTypes = {
  view: PropTypes.string.isRequired
};

export default GoalsViewSelect;
