import { ROLE } from './constants';

const userIsPrimary = role => role === ROLE.PRIMARY;
const userIsManager = role => role === ROLE.MANAGER;
const userIsExecutive = role => role === ROLE.EXEC;
const userIsBoard = role => role === ROLE.BOARD;
const userIsAdmin = role => role === ROLE.ADMIN;

// these user roles see read-only versions of evaluations that are not assigned to them
const userIsReadOnly = role => {
  return userIsBoard(role) || userIsExecutive(role) || userIsManager(role);
};

export {
  userIsPrimary,
  userIsAdmin,
  userIsExecutive,
  userIsBoard,
  userIsManager,
  userIsReadOnly
};
