import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  publishSection: {
    borderWidth: 'thin',
    borderTopStyle: 'dashed',
    paddingTop: 30,
    marginTop: 30,
    minHeight: '91px',
    '@media print': {
      display: 'none'
    }
  },
  label: {
    color: globalTheme.palette.neutral.main,
    fontWeight: 'bold',
    width: '200px'
  },
  text: {
    textAlign: 'right'
  },
  unpublishedText: {
    color: globalTheme.palette.neutral.medLight,
    textAlign: 'center'
  },
  publishedText: {
    color: globalTheme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  toggleSection: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  root: {
    width: 50,
    height: 23,
    padding: 0,
    margin: '0 10px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: globalTheme.palette.neutral.contrast,
    '&$checked': {
      transform: 'translateX(26px)',
      color: globalTheme.palette.neutral.contrast,
      '& + $track': {
        opacity: 1,
        backgroundColor: globalTheme.palette.secondary.main
      },
      '&.Mui-disabled + $track': {
        backgroundColor: globalTheme.palette.neutral.medLight
      }
    }
  },
  track: {
    border: `1px solid ${globalTheme.palette.neutral.light}`,
    height: '94%',
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: globalTheme.palette.neutral.light
  },
  checked: {}
}));

export default useStyles;
