import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormControl, Button, TextField } from '@material-ui/core';

import { useGoalState, useGoalDispatch } from '../../context/GoalContext';
import { DropdownSuggest, Modal } from '../common';
import useStyles from '../../styles/common/initiateEvalStyles';

const InitiateGoal = ({ isOpen, closeModal, renderType }) => {
  const {
    state: {
      goalFormConfig: { goalForEmail, goalTitle }
    }
  } = useGoalState();
  const { goalDispatch } = useGoalDispatch();

  const history = useHistory();
  const classes = useStyles();

  const chooseEmployee = user => {
    goalDispatch({
      type: 'setGoalFormConfig',
      payload: {
        goalForName: user ? user.displayName : null,
        goalForEmail: user ? user.mail || user.userPrincipalName : null,
        goalForJobNumber: user ? user.jobNumber : null,
        goalForEmployeeId: user ? user.employeeId : null
      }
    });
  };

  const chooseGoalTitle = event => {
    event.persist();
    const title = event.target.value;
    goalDispatch({
      type: 'setGoalFormConfig',
      payload: { goalTitle: title }
    });
  };

  const startGoal = () => {
    closeModal();
    history.push('/goal');
  };

  const redirectToGoalDashboard = () => {
    history.push('/goalsDashboard');
  };

  // display modal if on desktop
  const renderModalVersion = () => {
    return (
      <Modal
        modalTitle="Start New Goal"
        leftBtnText="Cancel"
        leftBtnClick={closeModal}
        rightBtnText="Create"
        rightBtnClick={startGoal}
        isOpen={isOpen}
        handleClose={closeModal}
        disabled={!goalForEmail || !goalTitle}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <DropdownSuggest
            labelName="Goal For"
            onChange={chooseEmployee}
            errorMessage=""
          />
        </FormControl>
        <TextField
          margin="dense"
          variant="outlined"
          label="Goal Title"
          className={classes.formControl}
          onChange={chooseGoalTitle}
          data-cy="chooseGoalTitle"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Modal>
    );
  };

  // display full page if on mobile
  const renderPageVersion = () => {
    return (
      <div className={classes.newEvalPageContainer}>
        <h2 className={classes.pageVersionTitle}>Start New Goal</h2>
        <FormControl variant="outlined" className={classes.pageFormControl}>
          <DropdownSuggest
            labelName="Goal For"
            onChange={chooseEmployee}
            errorMessage=""
          />
        </FormControl>
        <TextField
          className={classes.pageTextField}
          margin="dense"
          variant="outlined"
          label="Goal Title"
          onChange={chooseGoalTitle}
          data-cy="chooseGoalTitle"
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className={classes.actionButtons}>
          <Button
            className={`${classes.button} ${classes.negativeButton}`}
            onClick={redirectToGoalDashboard}
            variant="outlined"
            data-cy="cancelNewGoal"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.button} ${classes.positiveButton}`}
            onClick={startGoal}
            disabled={!goalForEmail || !goalTitle}
            data-cy="createNewGoal"
          >
            Create
          </Button>
        </div>
      </div>
    );
  };

  return renderType === 'modal' ? renderModalVersion() : renderPageVersion();
};

InitiateGoal.defaultProps = {
  isOpen: false,
  closeModal: () => {}
};

InitiateGoal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  renderType: PropTypes.string.isRequired
};

export default InitiateGoal;
