import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(163, 163, 163, 0.75)',
    '& p': {
      lineHeight: 2.3
    },
    '& h2': {
      fontWeight: 600,
      fontSize: '1.75rem'
    },
    '& h5': {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 2.3
    },
    '& h6': {
      fontWeight: 600
    },
    '& .MuiDialog-paperWidthSm': {
      minWidth: 300,
      borderRadius: 24
    },
    '& label.Mui-focused': {
      color: globalTheme.palette.neutral.main
    },
    '& .MuiDialogActions-root': {
      paddingBottom: 18
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      }
    }
  },
  dialogTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingBottom: 0,
      fontWeight: 600
    }
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: 15,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingBottom: 20,
      paddingTop: 20
    }
  },
  errorDialogContent: {
    color: globalTheme.palette.primary.main
  },
  dialogActionButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  button: {
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    padding: theme.spacing(1.75),
    minWidth: 95,
    '&.Mui-disabled': {
      backgroundColor: globalTheme.palette.primary.mainAlpha,
      color: globalTheme.palette.neutral.contrast
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      padding: theme.spacing(1.25)
    }
  },
  positiveButton: {
    color: globalTheme.palette.neutral.contrast,
    backgroundColor: globalTheme.palette.primary.main,
    '&:hover': {
      color: globalTheme.palette.primary.main,
      border: `1px solid ${globalTheme.palette.primary.main}`
    }
  },
  negativeButton: {
    color: 'black',
    backgroundColor: globalTheme.palette.neutral.contrast,
    borderColor: 'black'
  },
  deleteModalText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  extraSpace: {
    marginBottom: '6%',
    maxHeight: '350px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default useStyles;
