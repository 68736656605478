/* configuration for Azure AD connection */
const config = {
  clientId: process.env.REACT_APP_AD_CLIENT_ID,
  authority: process.env.REACT_APP_AD_AUTHORITY_LINK,
  scopes: [
    'Directory.AccessAsUser.All',
    'GroupMember.Read.All',
    'Group.Read.All'
  ]
};

export default config;
