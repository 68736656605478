import gql from 'graphql-tag';

export const CREATE_GOAL = gql`
  mutation CreateGoal(
    $shareToName: String
    $shareToEmail: String
    $shareToEmployeeId: Int
    $createdFor: String!
    $createdForEmail: String!
    $createdForEmployeeId: Int
    $createdForRole: String!
    $createdForJobNumber: String
    $title: String
    $description: String
    $startDate: DateTime
    $dueDate: DateTime
    $completedDate: DateTime
    $categoryId: Int
    $statusId: Int
    $trackId: Int
    $createdForFlag: Boolean
    $shareToFlag: Boolean
  ) {
    createGoal(
      shareToName: $shareToName
      shareToEmail: $shareToEmail
      shareToEmployeeId: $shareToEmployeeId
      createdFor: $createdFor
      createdForEmail: $createdForEmail
      createdForEmployeeId: $createdForEmployeeId
      createdForRole: $createdForRole
      createdForJobNumber: $createdForJobNumber
      title: $title
      description: $description
      startDate: $startDate
      dueDate: $dueDate
      completedDate: $completedDate
      categoryId: $categoryId
      statusId: $statusId
      trackId: $trackId
      createdForFlag: $createdForFlag
      shareToFlag: $shareToFlag
    ) {
      id
      createdOn
    }
  }
`;

export const CREATE_CALENDAR_EVENT_FOR_GOAL = gql`
  mutation CreateCalendarEventForGoal($token: String!, $goalId: Int!) {
    createCalendarEventForGoal(token: $token, goalId: $goalId)
  }
`;

export const UPDATE_GOAL = gql`
  mutation UpdateGoal(
    $goalId: Int!
    $title: String
    $description: String
    $startDate: DateTime
    $dueDate: DateTime
    $completedDate: DateTime
    $categoryId: Int
    $statusId: Int
    $trackId: Int
    $shareToName: String
    $shareToEmail: String
    $shareToEmployeeId: Int
    $createdForFlag: Boolean
    $shareToFlag: Boolean
  ) {
    updateGoal(
      goalId: $goalId
      title: $title
      description: $description
      startDate: $startDate
      dueDate: $dueDate
      completedDate: $completedDate
      categoryId: $categoryId
      statusId: $statusId
      trackId: $trackId
      shareToName: $shareToName
      shareToEmail: $shareToEmail
      shareToEmployeeId: $shareToEmployeeId
      createdForFlag: $createdForFlag
      shareToFlag: $shareToFlag
    ) {
      id
      createdOn
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation DeleteGoal($goalId: Int!, $trackId: Int, $categoryId: Int) {
    deleteGoal(goalId: $goalId, trackId: $trackId, categoryId: $categoryId) {
      id
    }
  }
`;

export const CREATE_GOAL_COMMENT = gql`
  mutation CreateGoalComment($goalId: Int!, $comment: String!) {
    createGoalComment(goalId: $goalId, comment: $comment) {
      id
      createdByEmployeeId
      createdOn
      comment
    }
  }
`;

export const DELETE_GOAL_COMMENTS = gql`
  mutation DeleteGoalComments($ids: [Int!]) {
    deleteGoalComments(ids: $ids) {
      comment
    }
  }
`;

export const BULK_SHARE_GOALS = gql`
  mutation BulkShareGoals(
    $goalIds: [Int!]
    $shareToEmail: String!
    $shareToName: String!
  ) {
    bulkShareGoals(
      goalIds: $goalIds
      shareToEmail: $shareToEmail
      shareToName: $shareToName
    ) {
      count
    }
  }
`;
