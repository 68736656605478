import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center'
    },
    '@media print': {
      fontSize: '1rem'
    }
  },
  subtext: {
    color: globalTheme.palette.neutral.medLight,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center'
    },
    '@media print': {
      fontSize: '0.8rem'
    }
  },
  assignDropdown: {
    width: '50%',
    alignSelf: 'center',
    marginTop: 30,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: 'black'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: globalTheme.palette.primary.main
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: globalTheme.palette.primary.main
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: globalTheme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  }
}));

export default useStyles;
