import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import {
  TextField,
  Typography,
  Paper,
  MenuItem,
  CircularProgress
} from '@material-ui/core';

import { useAuth } from '../../context/AuthContext';
import { SEARCH_USERS } from '../../graphql/evalQueries';
import { useDebounce, toCamel } from '../../helpers/helpers';
import useStyles from '../../styles/common/dropdownSuggestStyles';

const renderSuggestion = suggestionProps => {
  const {
    result,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps;

  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(result.displayName) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        whiteSpace: 'normal'
      }}
    >
      <Typography variant="inherit" noWrap>
        {result.displayName}
        <br />
        {result.mail}
      </Typography>
    </MenuItem>
  );
};

/* Component for dropdown that autocompletes users in Azure AD */
const DropdownSuggest = React.memo(
  ({ labelName, onChange, errorMessage, disabled }) => {
    const [searchText, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const classes = useStyles();
    const { refreshLogin } = useAuth();
    const client = useApolloClient();
    const debouncedSearchTerm = useDebounce(searchText, 500);

    // prevents hitting Azure AD on every keypress
    useEffect(() => {
      const getNewToken = async () => {
        setLoading(true);
        const newToken = await refreshLogin();
        setLoading(false);
        return newToken;
      };

      const fetchSearchResults = async (token, searchTerm) => {
        const {
          data: { searchUsers }
        } = await fetchUsers(token, searchTerm);
        setResults(searchUsers);
      };

      const updateSearchResults = async searchTerm => {
        let token = localStorage.getItem('accessToken');
        try {
          await fetchSearchResults(token, searchTerm);
        } catch (error) {
          token = await getNewToken();
          await fetchSearchResults(token, searchTerm);
        }
      };

      const fetchUsers = (token, searchTerm) => {
        return client.query({
          query: SEARCH_USERS,
          variables: { token, searchTerm }
        });
      };

      // fetch users from Azure AD matching the entered search term
      if (debouncedSearchTerm) {
        updateSearchResults(debouncedSearchTerm);
      } else {
        setResults([]);
      }
    }, [debouncedSearchTerm, client, refreshLogin]);

    const handleChange = (event, selectValue) => {
      event.persist();
      onChange(selectValue);
      setSearchTerm(event.target.value);
    };

    const renderInput = inputProps => {
      const { InputProps, ...other } = inputProps;
      return isLoading ? (
        <div className={classes.azureLoading}>
          <CircularProgress className={classes.progress} />
          <Typography className={classes.helperText}>
            Refreshing Login
          </Typography>
        </div>
      ) : (
        <TextField
          error={!!errorMessage}
          helperText={errorMessage}
          data-cy={`${toCamel(labelName)}Dropdown`}
          InputProps={{
            ...InputProps
          }}
          {...other}
          disabled={disabled}
          autoFocus={debouncedSearchTerm !== ''}
        />
      );
    };

    return (
      <Downshift
        id="downshift-simple"
        itemToString={result => (result ? `${result.displayName}` : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          clearSelection
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            onSelect: event => handleChange(event, selectedItem),
            onChange: clearSelection
          });
          return (
            <div>
              {disabled
                ? renderInput({
                    fullWidth: true,
                    label: labelName,
                    inputProps: { value: ' ' },
                    variant: 'outlined',
                    id: 'assignToField'
                  })
                : renderInput({
                    fullWidth: true,
                    label: labelName,
                    InputLabelProps: getLabelProps({ shrink: true }),
                    InputProps: { onBlur, onFocus },
                    inputProps,
                    variant: 'outlined',
                    id: 'assignToField'
                  })}
              <div {...getMenuProps()}>
                {isOpen && (
                  <Paper className={classes.paper} square>
                    {results.map((result, index) =>
                      renderSuggestion({
                        result,
                        index,
                        itemProps: getItemProps({ item: result }),
                        highlightedIndex,
                        selectedItem
                      })
                    )}
                  </Paper>
                )}
              </div>
            </div>
          );
        }}
      </Downshift>
    );
  }
);

DropdownSuggest.defaultProps = {
  disabled: false
};

DropdownSuggest.propTypes = {
  labelName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default DropdownSuggest;
