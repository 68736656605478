import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  commentContainer: {
    margin: 'auto',
    width: '90%',
    '&.MuiInputLabel': {
      color: 'black'
    },
    '& label.Mui-focused': {
      color: 'black'
    }
  },
  commentHeader: {
    color: globalTheme.palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal'
    }
  },
  hiddenComment: {
    display: 'none'
  }
}));

export default useStyles;
