import React from 'react';
import PropTypes from 'prop-types';

import { ROLE, DASHBOARD_VIEWS } from '../../helpers/constants';
import { useAuth } from '../../context/AuthContext';
import { useDashboardSearchDispatch } from '../../context/DashboardSearchContext';
import ViewSelector from '../common/ViewSelector';
import { userIsPrimary, userIsManager } from '../../helpers/roleHelpers';

/* Dropdown for selecting between different evaluations dashboard views */
const EvalsViewSelect = ({ view }) => {
  const {
    userData: { role, jobNumber }
  } = useAuth();

  const { dashboardSearchDispatch } = useDashboardSearchDispatch();

  const getViewOptions = () => {
    const baseViews = [
      DASHBOARD_VIEWS.ACTIVE.SHORT,
      DASHBOARD_VIEWS.ARCHIVED.SHORT
    ];

    const extraViews = {
      [ROLE.ADMIN]: DASHBOARD_VIEWS.ADMIN.SHORT,
      [ROLE.BOARD]: DASHBOARD_VIEWS.BOARD.SHORT,
      [ROLE.EXEC]: DASHBOARD_VIEWS.EXEC.SHORT,
      [ROLE.MANAGER]: DASHBOARD_VIEWS.MANAGER.SHORT
    };

    // primary users only see base views
    if (!role || userIsPrimary(role)) return baseViews;

    // Managers with job number prefix 'OH' should see managerViews, unless stakeholders want original functionality of it being hidden.
    // if (userIsManager(role) && jobNumber.startsWith('OH')) return baseViews;

    // other users see additional view based on their role
    return [...baseViews, extraViews[role]];
  };

  const setViewSelection = e => {
    dashboardSearchDispatch({
      type: 'setView',
      payload: e.target.value
    });
  };

  return (
    <ViewSelector
      view={view}
      setViewSelection={setViewSelection}
      viewOptions={getViewOptions()}
    />
  );
};

EvalsViewSelect.propTypes = {
  view: PropTypes.string.isRequired
};

export default EvalsViewSelect;
