import React, { useEffect, useState } from 'react';
import { ScrollTo } from 'react-scroll-to';
import { Fab } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

import useStyles from '../../styles/common/autoScrollStyles';

const AutoScroll = ({ type }) => {
  const classes = useStyles();

  const [fabHidden, setFabHidden] = useState(true);

  useEffect(() => {
    let isScrolling = null;
    let isMounted = true;

    window.addEventListener(
      'scroll',
      () => {
        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);
        if (isMounted) {
          setFabHidden(false);
        }

        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(() => {
          if (isMounted) {
            setFabHidden(true);
          }
        }, 2000);
      },
      false
    );

    return () => {
      isMounted = false;
      return isMounted;
    };
  });

  const arrowTypeDisplay = scrollType => {
    const scrollToButtonWindow = () => document.documentElement.offsetHeight;

    const types = {
      scrollToTop: (
        <ScrollTo>
          {({ scroll }) => (
            <div>
              <Fab
                size="small"
                data-cy="scrollToTop"
                aria-label="scroll back to button"
                onClick={() => scroll({ x: 0, y: 0 })}
                className={
                  fabHidden
                    ? classes.hiddenFab
                    : `${classes.redFab} ${classes.fabTop}`
                }
              >
                <ExpandLess className={classes.autoScrollIcon} />
              </Fab>
            </div>
          )}
        </ScrollTo>
      ),
      scrollToBottom: (
        <ScrollTo>
          {({ scroll }) => (
            <Fab
              size="small"
              data-cy="scrollToBottom"
              aria-label="scroll back to top"
              onClick={() => scroll({ x: 20, y: scrollToButtonWindow() })}
              className={
                fabHidden
                  ? classes.hiddenFab
                  : `${classes.redFab} ${classes.fabBottom}`
              }
            >
              <ExpandMore className={classes.autoScrollIcon} />
            </Fab>
          )}
        </ScrollTo>
      )
    };

    return types[scrollType];
  };

  return arrowTypeDisplay(type);
};

AutoScroll.propTypes = {
  type: PropTypes.string.isRequired
};

export default AutoScroll;
