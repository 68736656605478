import React from 'react';
import { CircularProgress, Container, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import useStyles from '../../styles/common/loadingSpinnerStyles';

const LoadingSpinner = ({ archivePending }) => {
  const classes = useStyles();

  const ARCHIVE_MESSAGE =
    'Archiving evaluation. Please do not refresh the page or data loss may occur.';

  return (
    <Container component="main" className={classes.root}>
      {archivePending && <Typography>{ARCHIVE_MESSAGE}</Typography>}
      <CircularProgress className={classes.progress} />
    </Container>
  );
};

LoadingSpinner.defaultProps = {
  archivePending: null
};

LoadingSpinner.propTypes = {
  archivePending: PropTypes.bool
};

export default LoadingSpinner;
