import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  searchBarContainer: {
    display: 'flex',
    marginTop: 40,
    marginBottom: 40,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'grey'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: globalTheme.palette.primary.main
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: globalTheme.palette.primary.main
    },
    '& .MuiSvgIcon-root': {
      '& .MuiSelect-iconOutlined': {
        [theme.breakpoints.down('md')]: {
          right: -10
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  calendarContainer: {
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      width: '59%',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('sm')]: {
      width: '71%',
      justifyContent: 'space-between',
      '& .MuiInput-root': {
        width: '90%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '97%',
      justifyContent: 'space-between'
    }
  },
  goalsDropDownsContainer: {
    width: '38%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      width: '59%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '71%',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexWrap: 'wrap'
    }
  },
  goalFormControl: {
    width: 200,
    '&.MuiSelect-outlined': {
      '&.MuiSelect-select': {
        backgroundColor: 'orange'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'grey'
      }
    },
    '&,MuiFormControl-root': {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1)
    }
  }
}));

export default useStyles;
