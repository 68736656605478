import React from 'react';
import PropTypes from 'prop-types';

import { useFormState, useFormDispatch } from '../../context/FormContext';
import { useAuth } from '../../context/AuthContext';
import { STATUS, FORM_TYPE } from '../../helpers/constants';
import { updateResponse, formIsReadOnly, idMatch } from '../../helpers/helpers';
import { userIsAdmin } from '../../helpers/roleHelpers';
import { Dropdown } from '../common';
import RadioButtons from './RadioButtons';
import AuditField from './AuditField';
import useStyles from '../../styles/evals/questionStyles';

const QuestionContainer = props => {
  const {
    state: { formResponses, formConfig, auditData }
  } = useFormState();

  return (
    <Question
      formResponses={formResponses}
      formConfig={formConfig}
      auditData={auditData}
      {...props}
    />
  );
};

const Question = React.memo(
  ({
    text,
    type,
    options,
    questionId,
    sectionId,
    formResponses,
    formConfig,
    auditData,
    description,
    sectionTitle,
    newResponses,
    setNewResponses
  }) => {
    const { dispatch } = useFormDispatch();
    const classes = useStyles();
    const {
      userData: { email, role, employeeId }
    } = useAuth();

    // Conditionals
    const formIsClosed = () => formConfig.status === STATUS.ARCHIVED.NAME;
    const formIsPublished = () => formConfig.status === STATUS.PUBLISHED.NAME;
    const formIsLocked = () =>
      formIsClosed() ||
      formIsPublished() ||
      formIsReadOnly(
        email,
        auditData.assignedToEmail,
        employeeId,
        auditData.assignedToEmployeeId,
        formConfig.view
      );
    const formTypeIsIntern = () =>
      formConfig.formTypeId === FORM_TYPE.INTERN.ID;
    const userIsEvaluated =
      idMatch(email, auditData.evaluationForEmail) ||
      idMatch(employeeId, auditData.evaluationForEmployeeId);

    const handleChange = field => event => {
      const response = event.target.value;
      updateResponse(
        sectionId,
        questionId,
        field,
        response,
        newResponses,
        setNewResponses,
        formResponses
      );
      dispatch({
        type: 'setResponseValue',
        payload: {
          sectionId,
          questionId,
          response,
          field
        }
      });
    };

    // disable dropdowns depending on user privileges
    const employeeFieldIsDisabled = () => {
      if (formIsLocked()) return true;
      if (userIsAdmin(role)) return false;
      return !userIsEvaluated;
    };

    const radioComponent = (questionOptions, classtype) => {
      return (
        <RadioButtons
          options={questionOptions}
          onChange={handleChange('reviewerResponse')}
          defaultValue={formResponses[sectionId][questionId].reviewerResponse}
          disabled={formIsLocked() || userIsEvaluated}
          sectionTitle={sectionTitle}
          classname={classtype}
        />
      );
    };

    const radioFormatDisplay = questionOptions => {
      if (formTypeIsIntern()) {
        if (
          sectionTitle === 'Internship' ||
          sectionTitle === 'Additional Feedback'
        ) {
          return (
            <section>
              <div
                className={
                  sectionTitle === 'Additional Feedback'
                    ? classes.feedbackSectionRadioButtonContainer
                    : classes.internshipSectionRadioButtonContainer
                }
              >
                <p
                  className={
                    sectionTitle === 'Internship'
                      ? classes.internshipYearsHeader
                      : classes.feedbackText
                  }
                >
                  {text}
                </p>
                {radioComponent(questionOptions, 'internshipRadioGroup')}
              </div>
            </section>
          );
        }

        if (sectionTitle === 'Attendance') {
          return (
            <div className={classes.attendanceRadioButtonContainer}>
              <p className={classes.internAttendanceText}>{text}</p>
              {radioComponent(questionOptions, 'attendanceRadioGroup')}
            </div>
          );
        }

        return (
          <div className={classes.internReviewRadioButtonContainer}>
            <p className={classes.internshipText}>{text}</p>
            {radioComponent(questionOptions, 'internReviewRadioGroup')}
          </div>
        );
      }

      // format for non-Intern forms
      return (
        <div className={classes.radioButtonContainer}>
          <div className={classes.descriptionContainer}>
            <p className={classes.description}>{description}</p>
            <p>{text}</p>
          </div>
          {radioComponent(questionOptions, 'radioGroup')}
        </div>
      );
    };

    const textFieldFormatDisplay = () => {
      if (formTypeIsIntern()) {
        if (sectionTitle === 'Additional Feedback') {
          return (
            <div className={classes.openEndedQuestions}>
              <div className={classes.questionText}>{text}</div>
            </div>
          );
        }

        return (
          <AuditField
            key={questionId}
            questionText={text}
            questionId={questionId}
            sectionId={sectionId}
            newResponses={newResponses}
            setNewResponses={setNewResponses}
          />
        );
      }

      // format for non-Intern forms
      return (
        <div className={classes.root}>
          <div className={classes.questionText}>{text}</div>
        </div>
      );
    };

    const questionTypeDisplay = (questionType, questionOptions) => {
      const types = {
        RadioButtons: <>{radioFormatDisplay(questionOptions)}</>,
        Dropdown: (
          <div className={classes.root}>
            <div className={classes.textContainer}>
              <div>{text}</div>
            </div>
            <div className={classes.dropdowns}>
              <Dropdown
                options={questionOptions}
                onChange={handleChange('employeeResponse')}
                labelName="Employee Rating"
                classname={classes.formControl}
                defaultValue={
                  formResponses[sectionId][questionId].employeeResponse
                }
                labelwidth={130}
                disabled={employeeFieldIsDisabled()}
                dropdownLocation="evaluation"
              />
              <Dropdown
                options={questionOptions}
                onChange={handleChange('reviewerResponse')}
                labelName="Reviewer Rating"
                classname={classes.formControl}
                defaultValue={
                  formResponses[sectionId][questionId].reviewerResponse
                }
                labelwidth={130}
                disabled={formIsLocked() || userIsEvaluated}
                dropdownLocation="evaluation"
              />
            </div>
          </div>
        ),
        TextField: <>{textFieldFormatDisplay()}</>
      };
      return types[questionType];
    };

    return (
      <div
        className={
          sectionTitle === 'Supervisor' && formTypeIsIntern()
            ? classes.supervisorRoot
            : classes.root
        }
      >
        {questionTypeDisplay(type, options)}
      </div>
    );
  }
);

Question.defaultProps = {
  type: '',
  options: [],
  description: '',
  formConfig: {
    ppeId: null,
    formType: '',
    formTypeId: null,
    status: ''
  },
  auditData: {},
  sectionTitle: ''
};

Question.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  formResponses: PropTypes.arrayOf(PropTypes.any).isRequired,
  newResponses: PropTypes.arrayOf(PropTypes.any).isRequired,
  setNewResponses: PropTypes.func.isRequired,
  formConfig: PropTypes.shape({
    ppeId: PropTypes.number,
    formType: PropTypes.string,
    formTypeId: PropTypes.number,
    status: PropTypes.string,
    view: PropTypes.string
  }),
  auditData: PropTypes.shape(),
  questionId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  sectionTitle: PropTypes.string
};

export default QuestionContainer;
