import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  paper: {
    zIndex: 1,
    left: 0,
    right: 0,
    marginTop: theme.spacing(1),
    maxHeight: 200,
    overflow: 'scroll'
  },
  progress: {
    color: globalTheme.palette.primary.main,
    maxWidth: 40,
    maxHeight: 40,
    [theme.breakpoints.down('sm')]: { maxWidth: 30, maxHeight: 30 }
  },
  azureLoading: {
    display: 'flex',
    justifyContent: 'center'
  },
  helperText: {
    marginLeft: 15,
    alignSelf: 'center',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: { fontSize: 19 }
  }
}));

export default useStyles;
