import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '../context/AuthContext';
import Navbar from './Navbar';
import { ErrorBoundary } from './common';

/* Redirects to login if the user is not authenticated */
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <div>
            <ErrorBoundary>
              <Navbar route={path} />
              <Component {...props} />
            </ErrorBoundary>
          </div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = {
  path: ''
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string
};

export default PrivateRoute;
