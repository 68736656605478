import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useSearchStyles = makeStyles(theme => ({
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'row-reverse',
      justifyContent: 'space-evenly'
    }
  },
  clearBtn: {
    color: globalTheme.palette.primary.main,
    marginLeft: 8,
    height: 28,
    width: 130,
    fontSize: 12,
    [theme.breakpoints.down('md')]: {
      marginRight: 48,
      marginLeft: 0,
      height: 'auto'
    }
  },
  dropDownsContainer: {
    width: '32%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  openSearch: {
    '&.MuiInput-underline.Mui-focused:after': {
      borderBottom: '2px solid black'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  openSearchContainer: {
    width: '48%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      width: '59%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '71%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '97%'
    }
  },
  searchIcon: {
    width: 40,
    height: 40
  },
  searchIconContainer: {
    marginBottom: 5,
    marginRight: 11,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 56
    }
  }
}));

export default useSearchStyles;
