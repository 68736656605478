import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Input
} from '@material-ui/core';
import { Brightness1 as TrackDot } from '@material-ui/icons';

import { GOAL_TRACK } from '../../helpers/constants';
import { toCamel } from '../../helpers/helpers';
import globalTheme from '../../styles/globalTheme';

const Dropdown = ({
  options,
  onChange,
  labelName,
  classname,
  labelwidth,
  defaultValue,
  disabled,
  multiple,
  multipleOptionsDefaultValue,
  renderValue,
  dropdownLocation,
  margin
}) => {
  const dropdownOptionFormat = component => {
    const opt = {
      dashboard: renderDashDropdowns(),
      evaluation: renderNativeDropdowns(),
      searchBar: renderSearchDropdowns(),
      initiateEval: renderNativeDropdowns(),
      goalForm: renderTrackOptions()
    };

    return opt[component];
  };

  const trackDot = option => {
    const { palette } = globalTheme;
    const trackColors = {
      [GOAL_TRACK.ON_TRACK.NAME]: { color: palette.active.main },
      [GOAL_TRACK.AT_RISK.NAME]: { color: palette.secondary.main },
      [GOAL_TRACK.OFF_TRACK.NAME]: { color: palette.primary.main },
      [GOAL_TRACK.NO_FLAG_SET.NAME]: { color: palette.neutral.light }
    };
    return (
      <ListItemIcon>
        <TrackDot style={trackColors[option]} />
      </ListItemIcon>
    );
  };
  // This function is specific to the dashboard (admin search bar multiple select dropdowns)
  const renderSearchDropdowns = () => {
    if (labelName === 'Track') {
      return options.map(option => (
        <MenuItem key={option} value={option}>
          <Checkbox
            checked={multipleOptionsDefaultValue.indexOf(option) > -1}
            style={{
              color:
                multipleOptionsDefaultValue.indexOf(option) > -1
                  ? globalTheme.palette.primary.main
                  : globalTheme.palette.neutral.dark
            }}
          />
          <ListItemText primary={option} />
          {trackDot(option)}
        </MenuItem>
      ));
    }

    return options.map(option => (
      <MenuItem key={option} value={option}>
        <Checkbox
          checked={multipleOptionsDefaultValue.indexOf(option) > -1}
          style={{
            color:
              multipleOptionsDefaultValue.indexOf(option) > -1
                ? globalTheme.palette.primary.main
                : globalTheme.palette.neutral.dark
          }}
        />
        <ListItemText primary={option} />
      </MenuItem>
    ));
  };

  const renderDashDropdowns = () => {
    return options.map(option => (
      <MenuItem key={option} value={option}>
        <ListItemText primary={option} />
      </MenuItem>
    ));
  };

  const renderNativeDropdowns = () => {
    return (
      <>
        <option value="" aria-label="Dropdown option" />
        {options.map(option => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </>
    );
  };

  const renderTrackOptions = () => {
    if (labelName === 'Track') {
      return options.map(option => (
        <MenuItem key={option} value={option}>
          <ListItemText primary={option} />
          {trackDot(option)}
        </MenuItem>
      ));
    }

    return options.map(option => (
      <MenuItem key={option} value={option}>
        <ListItemText primary={option} />
      </MenuItem>
    ));
  };

  return (
    <FormControl variant="outlined" className={classname}>
      {/* admin search bar drop downs need a different label view */}
      {dropdownLocation === 'searchBar' ? (
        <InputLabel shrink>{labelName}</InputLabel>
      ) : (
        <InputLabel>{labelName}</InputLabel>
      )}
      <Select
        native={
          dropdownLocation === 'evaluation' ||
          dropdownLocation === 'initiateEval'
        }
        labelWidth={labelwidth}
        value={
          dropdownLocation === 'searchBar'
            ? multipleOptionsDefaultValue
            : defaultValue
        }
        margin={margin}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        renderValue={renderValue}
        input={dropdownLocation === 'searchBar' ? <Input /> : null}
        displayEmpty={dropdownLocation === 'searchBar'}
        data-cy={`${toCamel(labelName)}Dropdown`}
      >
        {dropdownOptionFormat(dropdownLocation)}
      </Select>
    </FormControl>
  );
};
Dropdown.defaultProps = {
  options: [],
  defaultValue: '',
  classname: '',
  disabled: null,
  multiple: false,
  multipleOptionsDefaultValue: [],
  renderValue: null,
  dropdownLocation: null,
  margin: null
};

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  labelName: PropTypes.string.isRequired,
  labelwidth: PropTypes.PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  classname: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  multipleOptionsDefaultValue: PropTypes.arrayOf(PropTypes.string),
  renderValue: PropTypes.func,
  dropdownLocation: PropTypes.string,
  margin: PropTypes.string
};
export default Dropdown;
