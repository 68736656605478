import gql from 'graphql-tag';

const EVAL_INFO_DATA = gql`
  fragment EvalData on TblPpe {
    id
    ppeType {
      id
      displayName
    }
    ppeStatus {
      id
      displayName
    }
    assignedOn
    assignedTo
    assignedToEmail
    assignedToEmployeeId
    createdOn
    createdBy
    createdByEmail
    createdByEmployeeId
    evaluationFor
    evaluationForEmail
    evaluationForEmployeeId
    modifiedByEmail
    sent
  }
`;

// Search for users in Azure AD
export const SEARCH_USERS = gql`
  query SearchUsers($token: String!, $searchTerm: String!) {
    searchUsers(token: $token, searchTerm: $searchTerm) {
      id
      displayName
      givenName
      surname
      userPrincipalName
      mail
      jobTitle
      employeeId
      jobNumber
      districtNumber
      hireDate
    }
  }
`;

// Fetches all evaluations ever assigned to a given user
export const FETCH_PPES_FOR_USER = gql`
  query FetchPPEsForUser {
    fetchPPEsForUser {
      ...EvalData
      archivedAssigned
    }
  }
  ${EVAL_INFO_DATA}
`;

// Fetches all evaluations for users with roles lower than the current user
export const FETCH_EVALS_FOR_ROLE = gql`
  query FetchEvalsForRole {
    fetchEvalsForRole {
      ...EvalData
    }
  }
  ${EVAL_INFO_DATA}
`;

// Fetches all evaluations
export const FETCH_ALL_PPES = gql`
  query FetchAllPPEs($token: String!) {
    fetchAllPPEs(token: $token) {
      ...EvalData
      inactive
    }
  }
  ${EVAL_INFO_DATA}
`;

// fetches matching evaluations to determine last eval date
export const FETCH_LAST_EVAL_DATE = gql`
  query FetchLastEvalDate(
    $evaluationFor: String!
    $evaluationForEmail: String!
    $evaluationForEmployeeId: Int
    $orderBy: recentlyModified
  ) {
    fetchLastEvalDate(
      evaluationFor: $evaluationFor
      evaluationForEmail: $evaluationForEmail
      evaluationForEmployeeId: $evaluationForEmployeeId
      orderBy: $orderBy
    ) {
      modifiedOn
    }
  }
`;

// fetches matching archived evaluations to determine last modified date
export const FETCH_LAST_MODIFIED_DATE = gql`
  query FetchLastModifiedOnDate(
    $ppeId: Int!
    $evaluationFor: String!
    $evaluationForEmail: String!
    $evaluationForEmployeeId: Int
    $orderBy: recentlyModified
  ) {
    fetchLastModifiedOnDate(
      ppeId: $ppeId
      evaluationFor: $evaluationFor
      evaluationForEmail: $evaluationForEmail
      evaluationForEmployeeId: $evaluationForEmployeeId
      orderBy: $orderBy
    ) {
      modifiedOn
    }
  }
`;

export const FETCH_MATCHING_PPES = gql`
  query FetchMatchingPPEs(
    $createdOnSince: DateTime
    $createdOnUntil: DateTime
    $openSearch: String
    $status: [String]
    $formType: [String]
    $activeView: String
    $token: String
  ) {
    fetchMatchingPPEs(
      createdOnSince: $createdOnSince
      createdOnUntil: $createdOnUntil
      openSearch: $openSearch
      status: $status
      formType: $formType
      activeView: $activeView
      token: $token
    ) {
      ...EvalData
    }
  }
  ${EVAL_INFO_DATA}
`;

// fetches JSON string of form data
export const FETCH_FORM_STRUCTURE = gql`
  query FetchFormStructure($formTypeId: Int!) {
    fetchFormStructure(formTypeId: $formTypeId) {
      id
      attributes
      processType
    }
  }
`;

export const FETCH_PPE_INFO = gql`
  query FetchPpeInfo($ppeId: Int!) {
    fetchPpeInfo(ppeId: $ppeId) {
      id
      modifiedOn
      modifiedBy
      modifiedByEmail
    }
  }
`;

// fetches display names of all existing forms
export const FETCH_ALL_FORM_TYPES = gql`
  query FetchAllFormTypes {
    fetchAllFormTypes {
      id
      displayName
      isActive
    }
  }
`;

// fetches display names of all existing status types
export const FETCH_ALL_STATUS_TYPES = gql`
  query FetchAllStatusTypes {
    fetchAllStatusTypes {
      id
      displayName
    }
  }
`;

// fetches answer responses for an existing evaluation
export const FETCH_PPE_RESPONSES = gql`
  query FetchPPEResponses($ppeId: Int!) {
    fetchPPEResponses(ppeId: $ppeId) {
      id
      questionId
      sectionId
      employeeResponse
      reviewerResponse
    }
  }
`;

// fetches comments for a given evaluation
export const FETCH_PPE_COMMENTS = gql`
  query FetchPPEComments($ppeId: Int!) {
    fetchPPEComments(ppeId: $ppeId) {
      id
      sectionId
      comment
      createdBy
      createdByEmail
      createdOn
      createdByEmployeeId
    }
  }
`;

// check if a ppe currently exists for a user
export const CHECK_FOR_EXISTING_PPE = gql`
  query CheckForExistingPPE($userEmail: String!, $userId: Int) {
    checkForExistingPPE(userEmail: $userEmail, userId: $userId) {
      evaluationFor
      evaluationForEmail
      assignedTo
      assignedToEmail
    }
  }
`;
