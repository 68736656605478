import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { DASHBOARD_VIEWS } from '../helpers/constants';

const DashboardSearchStateContext = createContext();
const DashboardSearchDispatchContext = createContext();

const useDashboardSearchState = () => useContext(DashboardSearchStateContext);
const useDashboardSearchDispatch = () =>
  useContext(DashboardSearchDispatchContext);

const initialState = {
  view: DASHBOARD_VIEWS.ACTIVE.SHORT,
  endDateDisabled: true,
  startDate: {},
  endDate: {},
  openSearchValue: '',
  status: [],
  dashboardSearchResults: [],
  formType: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setView': {
      if (action.payload === 'Archived') {
        return {
          ...state,
          status: [],
          view: action.payload
        };
      }
      return {
        ...state,
        view: action.payload
      };
    }
    case 'setStartDate':
      return {
        ...state,
        endDateDisabled: false,
        startDate: { ...action.payload }
      };
    case 'resetStartDate':
      return {
        ...state,
        endDateDisabled: true,
        startDate: {}
      };
    case 'setEndDate':
      return {
        ...state,
        endDate: { ...action.payload }
      };
    case 'setStatus':
      return {
        ...state,
        status: action.payload
      };
    case 'setOpenSearch':
      return {
        ...state,
        openSearchValue: action.payload
      };
    case 'updateSearchResultsData':
      return {
        ...state,
        dashboardSearchResults: action.payload
      };
    case 'setFormType':
      return {
        ...state,
        formType: action.payload
      };
    case 'clearSearch':
      return {
        ...state,
        endDateDisabled: true,
        startDate: {},
        endDate: {},
        openSearchValue: '',
        status: [],
        formType: []
      };
    default:
      throw new Error();
  }
};

const DashboardSearchContextProvider = ({ children }) => {
  const [state, dashboardSearchDispatch] = useReducer(reducer, initialState);
  return (
    <DashboardSearchStateContext.Provider value={{ state }}>
      <DashboardSearchDispatchContext.Provider
        value={{ dashboardSearchDispatch }}
      >
        {children}
      </DashboardSearchDispatchContext.Provider>
    </DashboardSearchStateContext.Provider>
  );
};

DashboardSearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export {
  DashboardSearchContextProvider,
  useDashboardSearchState,
  useDashboardSearchDispatch
};
