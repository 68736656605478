import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PageError from './PageError';
import userAgentApplication from '../../ad-config/userAgentApplication';

class ErrorBoundary extends Component {
  state = { hasError: false };

  ERROR_MESSAGE = {
    TITLE: 'Oh no! Something went wrong.',
    SUBTITLE:
      'Please refresh the page. If the error persists reach out to your system administrator.'
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (error.message.includes('[TOKENERROR]')) {
      userAgentApplication.logout();
      localStorage.clear();
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <PageError
          title={this.ERROR_MESSAGE.TITLE}
          subtitle={this.ERROR_MESSAGE.SUBTITLE}
          notFoundPage={false}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
