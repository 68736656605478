import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const FormStateContext = createContext();
const FormDispatchContext = createContext();

const useFormState = () => useContext(FormStateContext);
const useFormDispatch = () => useContext(FormDispatchContext);

const initialState = {
  formConfig: {
    ppeId: null,
    formType: '',
    formTypeId: null,
    status: '',
    createdBy: '',
    createdByEmail: '',
    createdByEmployeeId: null,
    sent: null,
    view: null
  },
  auditData: {},
  formStructure: {},
  formResponses: [],
  formComments: [],
  printVersion: false,
  existingPpeInfo: null,
  unsavedEvalChanges: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setFormConfig':
      return {
        ...state,
        formConfig: {
          ...state.formConfig,
          ...action.payload
        }
      };
    case 'setFormConfigObject':
      return {
        ...state,
        formConfig: action.payload
      };
    case 'setFormStructure':
      return {
        ...state,
        formStructure: action.payload
      };
    case 'setResponses':
      return {
        ...state,
        formResponses: action.payload
      };
    case 'setResponseValue': {
      const { sectionId, questionId, field, response } = action.payload;
      const updatedResponses = [...state.formResponses];

      updatedResponses[sectionId][questionId][field] = response;
      return {
        ...state,
        formResponses: updatedResponses
      };
    }
    case 'setComments':
      return {
        ...state,
        formComments: action.payload
      };
    case 'setAuditData':
      return {
        ...state,
        auditData: action.payload
      };
    case 'setAuditDataValue':
      return {
        ...state,
        auditData: {
          ...state.auditData,
          ...action.payload
        }
      };
    case 'setPrintVersion':
      return {
        ...state,
        printVersion: action.payload
      };
    case 'setExistingPpeInfo':
      return {
        ...state,
        existingPpeInfo: action.payload
      };
    case 'setUnsavedEvalChanges':
      return {
        ...state,
        unsavedEvalChanges: action.payload
      };
    default:
      throw new Error();
  }
};

const FormContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FormStateContext.Provider value={{ state }}>
      <FormDispatchContext.Provider value={{ dispatch }}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};

FormContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { FormContextProvider, useFormState, useFormDispatch };
