import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

import { useFormState } from '../../context/FormContext';
import { useAuth } from '../../context/AuthContext';
import { STATUS } from '../../helpers/constants';
import useStyles from '../../styles/evals/publishStyles';
import { idMatch } from '../../helpers/helpers';

/* Section for toggling an evaluation's Published status */
const PublishSection = ({ handlePublishToggle }) => {
  const {
    state: { formConfig, auditData }
  } = useFormState();

  const { userData } = useAuth();

  const classes = useStyles();

  // Conditionals
  const formIsPublished = () => formConfig.status === STATUS.PUBLISHED.NAME;
  const userIsEvaluated =
    idMatch(userData.email, auditData.evaluationForEmail) ||
    idMatch(userData.employeeId, auditData.evaluationForEmployeeId);

  return (
    <section className={classes.publishSection}>
      <div className={classes.toggleSection}>
        <Switch
          checked={formIsPublished()}
          onChange={handlePublishToggle}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            track: classes.track,
            checked: classes.checked
          }}
          data-cy="publishToggle"
          disabled={userIsEvaluated}
        />
      </div>
      <div className={classes.toggleSection}>
        {!formIsPublished() ? (
          <p className={`${classes.text} ${classes.unpublishedText}`}>
            PUBLISH TO MAKE REVIEWER COMMENTS VISIBLE TO EMPLOYEE IN A SHARED
            VIEW
          </p>
        ) : (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className={`${classes.text} ${classes.publishedText}`}>
                EVALUATION NOW VIEWABLE TO EMPLOYEE BEING REVIEWED
                <br />
                REVIEWER MUST UNPUBLISH TO MAKE EDITS OR CONTINUE IN PROCESS
              </p>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

PublishSection.propTypes = {
  handlePublishToggle: PropTypes.func.isRequired
};

export default PublishSection;
