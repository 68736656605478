import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

import useStyles from '../../styles/common/pageErrorStyles';

const PageError = props => {
  const classes = useStyles();
  const { title, subtitle, notFoundPage } = props;
  return (
    <div className={classes.wrapper}>
      <Paper className={classes.root}>
        {notFoundPage ? <h1>{title}</h1> : <h3>{title}</h3>}
      </Paper>
      <h2 className={classes.subtitle}>{subtitle}</h2>
    </div>
  );
};

PageError.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  notFoundPage: PropTypes.bool.isRequired
};

export default PageError;
