import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';

import globalTheme from '../../styles/globalTheme';

const DatePickerCalendar = ({
  labelName,
  id,
  disabled,
  minDate,
  maxDate,
  clearCalendar,
  onChange,
  selectedValue,
  setDisableFuture,
  setDisablePast,
  dispatch,
  startDate,
  endDate,
  isClearable,
  inputVariant,
  clearLabel
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarCleared, setCalendarCleared] = useState(clearCalendar);
  const startDateLabels =
    labelName === 'Start Date' || labelName === 'Due Date From';
  const endDateLabels = labelName === 'End Date' || labelName === 'Due Date To';
  useEffect(() => {
    setCalendarCleared(clearCalendar);
  }, [clearCalendar]);

  // If no minDate is given, then allow the calendar to select dates starting 01/01/1980
  const minDatePlaceHolder = moment('1980-01-01', 'YYYY-MM-DD').format(
    'YYYY-MM-DD HH:mm'
  );

  const updateDateChange = e => {
    setSelectedDate(e);
    setCalendarCleared(false);
    const shortDate = moment(e).format('YYYY-MM-DD HH:mm');

    if (startDateLabels && e !== null) {
      dispatch({
        type: 'setStartDate',
        payload: { shortDate }
      });
    }
    if (startDateLabels && e === null) {
      dispatch({
        type: 'resetStartDate',
        payload: {}
      });
    }
    if (endDateLabels && e !== null) {
      dispatch({
        type: 'setEndDate',
        payload: { shortDate }
      });
    }
  };

  const chooseDateValue = () => {
    if (disabled) {
      return null;
    }
    if (endDateLabels && endDate.shortDate) {
      return endDate.shortDate;
    }
    if (startDateLabels && startDate.shortDate) {
      return startDate.shortDate;
    }
    if (calendarCleared.first || calendarCleared.second) {
      return null;
    }
    return selectedDate;
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <KeyboardDatePicker
        value={onChange ? selectedValue : chooseDateValue()}
        onChange={e => (onChange ? onChange(e) : updateDateChange(e))}
        clearable={isClearable}
        clearLabel={clearLabel}
        autoOk
        disableFuture={setDisableFuture}
        disablePast={setDisablePast}
        label={labelName}
        disableToolbar
        format="MM/DD/YYYY"
        data-cy={id}
        id={id}
        disabled={disabled}
        minDate={minDate || minDatePlaceHolder}
        maxDate={maxDate || undefined}
        okLabel=""
        minDateMessage="End date precedes start date"
        inputVariant={inputVariant}
      />
    </ThemeProvider>
  );
};

DatePickerCalendar.defaultProps = {
  minDate: '',
  maxDate: '',
  onChange: null,
  selectedValue: null,
  clearCalendar: { first: false, second: false },
  setDisableFuture: false,
  setDisablePast: false,
  dispatch: () => {},
  startDate: {},
  endDate: {},
  inputVariant: 'standard',
  clearLabel: 'CLEAR'
};

DatePickerCalendar.propTypes = {
  labelName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  clearCalendar: PropTypes.shape({
    first: PropTypes.bool,
    second: PropTypes.bool
  }),
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setDisableFuture: PropTypes.bool,
  setDisablePast: PropTypes.bool,
  dispatch: PropTypes.func,
  startDate: PropTypes.shape({ shortDate: PropTypes.string }),
  endDate: PropTypes.shape({ shortDate: PropTypes.string }),
  isClearable: PropTypes.bool.isRequired,
  inputVariant: PropTypes.string,
  clearLabel: PropTypes.string
};

export default DatePickerCalendar;
