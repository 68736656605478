import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  goalSharedContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: 30,
    paddingTop: 30,
    borderWidth: 'thin',
    borderTopStyle: 'dashed',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '55px'
    }
  },
  goalNotSharedContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    paddingTop: 30,
    borderWidth: 'thin',
    borderTopStyle: 'dashed',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '55px'
    }
  },
  shareWithDropdown: {
    width: '50%',
    alignSelf: 'center',
    marginTop: 30,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: 'black'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: globalTheme.palette.primary.main
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: globalTheme.palette.primary.main
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: globalTheme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  subtitle: {
    color: globalTheme.palette.primary.main,
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      paddingTop: 30
    }
  },
  subtext: {
    color: globalTheme.palette.neutral.medLight,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center'
    },
    '@media print': {
      fontSize: '0.8rem'
    }
  },
  sharedWithSubtitle: {
    textAlign: 'center',
    paddingLeft: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 6,
      fontWeight: 400,
      fontSize: 18,
      paddingLeft: 0,
      alignSelf: 'center',
      paddingTop: 30
    }
  },
  sharedWithHeader: {
    color: globalTheme.palette.primary.main,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      fontSize: 20
    }
  },
  shareSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 30
    }
  }
}));

export default useStyles;
