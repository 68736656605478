import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  autoScrollIcon: {
    color: 'white'
  },
  redFab: {
    backgroundColor: globalTheme.palette.primary.main,
    width: 35,
    height: 35,
    zIndex: 100,
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      minWidth: 20,
      minHeight: 20,
      marginRight: 10
    },
    '@media print': {
      display: 'none'
    }
  },
  fabBottom: {
    position: 'fixed',
    right: '3%',
    bottom: '44%',
    zIndex: 100,
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      right: '2%',
      bottom: '42.5%'
    }
  },
  fabTop: {
    position: 'fixed',
    right: '3%',
    top: '44%',
    zIndex: 100,
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      right: '2%',
      top: '42.5%'
    }
  },
  hiddenFab: {
    display: 'none'
  }
}));

export default useStyles;
