import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  employeeInfoField: {
    width: '75%',
    margin: theme.spacing(0.75),
    [theme.breakpoints.up('sm')]: {
      width: '32%'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black'
    },
    '@media print': {
      width: '250px'
    }
  },
  additionalFields: {
    width: '250px',
    margin: theme.spacing(0.75),
    '@media print': {
      width: '270px'
    }
  },
  internAttendanceFields: {
    width: '50%'
  },
  datePicker: {
    margin: 6,
    width: 'auto',
    '& .MuiOutlinedInput-input': {
      padding: 10.5
    }
  }
}));

export default useStyles;
