import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { TextField, IconButton, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';

import { Dropdown, DatePickerCalendar } from '../common';
import { FORM_TYPE, STATUS } from '../../helpers/constants';
import { getErrorMessage } from '../../helpers/helpers';
import { FETCH_MATCHING_PPES } from '../../graphql/evalQueries';
import {
  useDashboardSearchState,
  useDashboardSearchDispatch
} from '../../context/DashboardSearchContext';
import useStyles from '../../styles/common/dashboardSearchBarStyles';
import useSearchStyles from '../../styles/common/searchBarStyles';

const getStatusTypes = () =>
  Object.keys(STATUS).map(status => STATUS[status].NAME);

/* Component for search functionality */
const DashboardSearchBar = ({ activeView, allPPEData }) => {
  const classes = useStyles();
  const searchClasses = useSearchStyles();
  const [clearCalendar, setClearCalendar] = useState({
    first: false,
    second: false
  });
  const [searching, setSearching] = useState(false);

  const {
    state: {
      status,
      openSearchValue,
      startDate,
      endDateDisabled,
      formType,
      endDate
    }
  } = useDashboardSearchState();
  const { dashboardSearchDispatch } = useDashboardSearchDispatch();

  const [
    fetchMatchingPPEs,
    { data: searchData, error: searchError }
  ] = useLazyQuery(FETCH_MATCHING_PPES, {
    fetchPolicy: 'no-cache',
    onCompleted: () => updateSearchData()
  });

  // query the database for search results
  const submitSearch = e => {
    if (e) e.preventDefault();
    const start = getDate('calculateStart');
    const end = getDate('calculateEnd');

    // prevent unnecessary fetching if no search params added
    if (openSearchValue || start || end || status.length || formType.length) {
      const token =
        activeView === 'Admin' ? localStorage.getItem('accessToken') : '';

      fetchMatchingPPEs({
        variables: {
          createdOnSince: start,
          createdOnUntil: end,
          openSearch: openSearchValue,
          status,
          formType,
          activeView,
          token
        }
      });
      // set this piece of state in order to trigger the updateSearchData cb function in case a user searches for the same thing after clearing and searching again
      setSearching(!searching);
    } else {
      refetchAllPPEs();
    }
  };

  // if a user enters search criteria & then changes an evaluation & goes back to the dashboard...
  // ... refetch the evaluations that match the search criteria in order to get the most up to date status
  const useMountEffect = fun => useEffect(fun, []);
  useMountEffect(submitSearch);

  const useCheckViewEffect = cbFn => useEffect(cbFn, [activeView]);
  useCheckViewEffect(submitSearch);

  // Dispatch search results to the context
  const updateSearchData = React.useCallback(() => {
    if (searchData) {
      const searchResultsArray = searchData.fetchMatchingPPEs;
      dashboardSearchDispatch({
        type: 'updateSearchResultsData',
        payload: { searchResultsArray }
      });
    }
  }, [searchData, dashboardSearchDispatch]);

  useEffect(() => {
    updateSearchData();
  }, [searching, updateSearchData]);

  const updateOpenSearch = event => {
    dashboardSearchDispatch({
      type: 'setOpenSearch',
      payload: event.target.value
    });
  };

  const chooseFormStatus = event => {
    dashboardSearchDispatch({
      type: 'setStatus',
      payload: event.target.value
    });
  };

  const chooseFormTypes = event => {
    dashboardSearchDispatch({
      type: 'setFormType',
      payload: event.target.value
    });
  };

  const getFormTypeDropdownOptions = () => {
    const options = Object.keys(FORM_TYPE).sort();
    return options.map(option => FORM_TYPE[option].NAME);
  };

  const getDate = actionType => {
    const startDateSelected = document.getElementById('startDate').value;
    const endDateSelected = document.getElementById('endDate').value;

    if (startDateSelected) {
      if (actionType === 'calculateStart') {
        const newStartDate = `${startDateSelected} 00:01:00.0000000`;
        return new Date(newStartDate);
      }
      if (!endDateSelected) {
        const shortDate = moment(new Date()).format('YYYY-MM-DD HH:mm');
        dashboardSearchDispatch({
          type: 'setEndDate',
          payload: { shortDate }
        });
        return new Date();
      }
      const newEndDate = `${endDateSelected} 23:59:59.9999999`;
      return new Date(newEndDate);
    }
    return null;
  };

  if (searchError) {
    const message = getErrorMessage(searchError);
    throw new Error(
      `[ERROR] Failed to fetch all matching evaluations: ${message}`
    );
  }

  const refetchAllPPEs = () => {
    dashboardSearchDispatch({
      type: 'updateSearchResultsData',
      payload: { allPPEData }
    });
  };

  const clearSearch = () => {
    document.getElementById('openSearch').value = null;

    if (clearCalendar.first) {
      setClearCalendar({
        first: false,
        second: true
      });
    } else {
      setClearCalendar({
        first: true,
        second: false
      });
    }
    dashboardSearchDispatch({ type: 'clearSearch' });

    // re-fetch all evaluations if search is cleared
    refetchAllPPEs();
  };

  const filterStatusTypes = () => {
    const allStatusTypes = getStatusTypes();
    if (activeView === 'Active' || activeView === 'Archived') {
      return allStatusTypes.filter(type => type !== 'ARCHIVED');
    }
    return allStatusTypes;
  };

  const chooseFormTypeRenderValue = selected => {
    const numberOfFormTypes = Object.keys(FORM_TYPE).length;

    if (!selected.length || selected.length === numberOfFormTypes) {
      return 'All Form Types';
    }
    if (selected.length === 1) {
      return selected;
    }
    return `Selected: ${selected.length}`;
  };

  return (
    <div
      className={classes.searchBarContainer}
      role="presentation"
      onKeyDown={e => (e.key === 'Enter' ? submitSearch(e) : null)}
    >
      <div className={searchClasses.openSearchContainer}>
        <TextField
          label="Last Name or Email"
          className={searchClasses.openSearch}
          id="openSearch"
          onChange={updateOpenSearch}
          value={openSearchValue}
          data-cy="nameOrEmail"
        />
      </div>
      <div className={searchClasses.dropDownsContainer}>
        <Dropdown
          options={filterStatusTypes()}
          onChange={chooseFormStatus}
          labelName="Status"
          labelwidth={80}
          classname={classes.formControl}
          data-cy="statusTypeDropdown"
          disabled={activeView === 'Archived'}
          multiple
          multipleOptionsDefaultValue={status}
          renderValue={selected => {
            if (!selected.length || selected.length === 3) {
              return 'All Statuses';
            }
            return selected.join(', ');
          }}
          dropdownLocation="searchBar"
        />
        <Dropdown
          options={getFormTypeDropdownOptions()}
          onChange={chooseFormTypes}
          labelName="Form Type"
          labelwidth={80}
          classname={classes.formControl}
          data-cy="formTypeDropdown"
          disabled={false}
          multiple
          multipleOptionsDefaultValue={formType}
          renderValue={selected => chooseFormTypeRenderValue(selected)}
          dropdownLocation="searchBar"
        />
      </div>
      <div className={classes.calendarContainer}>
        <div className={classes.firstCalendar}>
          <DatePickerCalendar
            labelName="Start Date"
            id="startDate"
            disabled={false}
            clearCalendar={clearCalendar}
            dispatch={dashboardSearchDispatch}
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate.shortDate}
            setDisableFuture
            isClearable
          />
        </div>
        <div className={classes.dateRange}>to</div>
        <DatePickerCalendar
          labelName="End Date"
          id="endDate"
          disabled={endDateDisabled}
          minDate={startDate.shortDate}
          clearCalendar={clearCalendar}
          dispatch={dashboardSearchDispatch}
          startDate={startDate}
          endDate={endDate}
          setDisableFuture
          isClearable={false}
        />
      </div>
      <div className={searchClasses.buttonDiv}>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={submitSearch}
          data-cy="dashboardSearchBtn"
          className={searchClasses.searchIconContainer}
        >
          <SearchIcon className={searchClasses.searchIcon} />
        </IconButton>
        <Button
          variant="outlined"
          className={searchClasses.clearBtn}
          onClick={clearSearch}
          data-cy="clearSearchBtn"
        >
          Clear Search
        </Button>
      </div>
    </div>
  );
};

DashboardSearchBar.defaultProps = {
  allPPEData: null
};

DashboardSearchBar.propTypes = {
  activeView: PropTypes.string.isRequired,
  allPPEData: PropTypes.objectOf(PropTypes.any)
};

export default DashboardSearchBar;
