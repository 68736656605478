const getDropdownSelectionOptions = data => {
  return Object.values(data).map(option => option.NAME);
};

// on the goals form 'select a category' is an option, on the dashboard, it shows as 'none' instead
const getCategoryDashboardOptions = data => {
  return Object.values(data).map(option => {
    return option.NAME === 'Select a Category' ? 'None' : option.NAME;
  });
};

const getSelectedOptionId = (data, optionName) => {
  if (optionName) {
    const matchingOption = Object.values(data).find(
      option => option.NAME === optionName
    );
    return matchingOption.ID;
  }

  return null;
};

const formatDate = date => (date ? new Date(date) : null);

export {
  getDropdownSelectionOptions,
  getSelectedOptionId,
  formatDate,
  getCategoryDashboardOptions
};
