import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import GoalForm from '../../components/goals/GoalForm';
import { useGoalState } from '../../context/GoalContext';

const GoalContainer = () => {
  const {
    state: { goalFormConfig }
  } = useGoalState();
  const history = useHistory();

  useEffect(() => {
    const formConfigHasValue = Object.values(goalFormConfig);
    const nonNullValues = formConfigHasValue.filter(Boolean).length;
    if (!nonNullValues) {
      history.push('/goalsDashboard');
    }
  }, [goalFormConfig, history]);

  return <GoalForm />;
};

export default GoalContainer;
